// react
import { FC } from "react";
import { COLORS } from "../../constants";

interface TargetDataComponentProps {
  x: number;
  y: number;
  width: number;
  datum: {
    y: number;
  };
  text?: string;
  strokeDasharray?: string;
  events: {
    onPress?: () => void;
  };
}

const TargetDataComponent: FC<TargetDataComponentProps> = ({
  x,
  y,
  width,
  datum,
  events,
  strokeDasharray = "4",
  text,
  ...props
}) => {
  if (Math.abs(datum?.y) === 0) {
    return null;
  }

  return (
    <rect
      x={x - width / 2}
      y={y}
      onClick={events?.onPress}
      width={width}
      height="2"
      strokeDasharray={strokeDasharray}
      strokeWidth={1.5}
      fill={"transparent"}
      stroke={COLORS.purple}
      {...props}
    />
  );
};

export default TargetDataComponent;
