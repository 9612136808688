import { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { IProps } from "./types";
import styles from "./styles.module.scss";
import { CrossIcon } from "../../assets";
import { Button, EditableUnit, Input, Selector } from "../../components";
import { ingredientsCategories } from "../../constants";
import {
  getIngredientById,
  updateIngredient,
} from "../../services/ingredients.service";
import { toast } from "react-hot-toast";
import { IIngredient, IUnit } from "../../models";

const EditIngredient: FC<IProps> = ({
  isOpen,
  setIsOpen,
  onGetIngredients,
  ingredientInfo,
  ingredientId,
}): JSX.Element => {
  const [name, setName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [calories, setCalories] = useState<number>();
  const [proteins, setProteins] = useState<number>();
  const [fats, setFats] = useState<number>();
  const [carbs, setCarbs] = useState<number>();
  const [fiber, setFiber] = useState<number>();
  const [alcohol, setAlcohol] = useState<number>();

  const [brand, setBrand] = useState("");
  const [addUnitName, setAddUnitName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ingredient, setIngredient] = useState<IIngredient | undefined>(
    ingredientInfo
  );
  const [ingredientUnits, setIngredientUnits] = useState<IUnit[]>([]);

  const getIngredientInfo = async (id: number) => {
    try {
      setIsLoading(true);
      const { data } = await getIngredientById(id);
      if (data?.success) {
        setIngredient(data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (ingredientId) {
      getIngredientInfo(ingredientId);
    }
  }, [ingredientId]);

  useEffect(() => {
    if (ingredient) {
      setName(ingredient?.name || "");
      setCategory(ingredient?.category || "");
      setCalories(ingredient?.calories);
      setProteins(ingredient?.proteins);
      setFats(ingredient?.fats);
      setCarbs(ingredient?.carbs);
      setFiber(ingredient?.fiber);
      setAlcohol(ingredient?.alco);
      setBrand(ingredient?.brand || "");
      setIngredientUnits(ingredient?.units?.map((item) => ({ ...item })) || []);
    }
  }, [ingredient, isOpen]);

  const [nameError, setNameError] = useState<string>("");

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onChangeBrand = (e: ChangeEvent<HTMLInputElement>) => {
    setBrand(e.target.value);
  };

  const onUpdateIngredient = async () => {
    try {
      setIsLoadingCreate(true);
      const { data } = await updateIngredient(
        name,
        category,
        ingredientUnits,
        ingredient?.id || 0,
        brand || ""
      );
      if (data?.success) {
        toast.success("The ingredient was updated successfully");
        setName("");
        setCalories(0);
        setProteins(0);
        setFats(0);
        setFiber(0);
        setCarbs(0);
        setAlcohol(0);

        setIsOpen(false);
        onGetIngredients();
        setBrand("");
        setNameError("");
        setIngredientUnits([]);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error?.message?.[0]?.message || "Something went wrong");
    } finally {
      setIsLoadingCreate(false);
    }
  };

  const onChangeAddUnitName = (e: ChangeEvent<HTMLInputElement>) => {
    setAddUnitName(e.target.value);
  };

  const onAddUnit = (name: string) => {
    if (!addUnitName) {
      return;
    }
    setIngredientUnits((prev) => [
      ...prev,
      {
        name: name,
        grams: 0,
        description: "",
        isDefault: false,
        status: false,
        calories: 0,
        proteins: 0,
        fats: 0,
        carbs: 0,
        fiber: 0,
        alco: 0,
      },
    ]);
    setAddUnitName("");
  };
  const checkIsUnitsValid = () => {
    return ingredientUnits.some((el) => {
      if (el.status && el.isDefault) {
        return true;
      } else {
        return false;
      }
    });
  };
  const isDisabled =
    name?.length < 2 ||
    !category ||
    calories === undefined ||
    proteins === undefined ||
    fats === undefined ||
    carbs === undefined ||
    fiber === undefined ||
    alcohol === undefined ||
    !checkIsUnitsValid();

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h6>Edit Ingredient</h6>
        <div onClick={() => setIsOpen(false)} className={styles.closeButton}>
          <CrossIcon />
        </div>
        <div className={styles.inputContainer}>
          <Input
            value={name}
            onChange={onChangeName}
            label="Ingredient Name"
            placeholder="Ingredient Name"
            error={nameError}
          />
          <Input
            value={brand}
            onChange={onChangeBrand}
            label="Brand"
            placeholder="Brand"
          />
          <Selector
            value={category}
            setValue={(value) => {
              setCategory(value);
            }}
            data={ingredientsCategories}
            label="Category"
          />
          <span className={styles.sectionTitle}>
            The amount of nutrients must be indicated per 1 unit.
          </span>
          {ingredientUnits?.map((item, index) => (
            <div key={index} className={styles.editableUnitWrapper}>
              <EditableUnit
                index={index}
                item={item}
                setIngredientUnits={setIngredientUnits}
              />
              <div className={styles.editableUnitDivider} />
            </div>
          ))}

          <Input
            placeholder="Unit Name"
            value={addUnitName}
            onChange={onChangeAddUnitName}
            wrapperStyles={styles.addUnitTitle}
          />

          <Button
            onClick={() => {
              onAddUnit(addUnitName);
            }}
            title={"Add Unit"}
            size="x-small"
            styleType="outlined"
            customStyles={styles.addUnitButton}
          />
          <Button
            title="Save"
            size="medium"
            styleType={isDisabled ? "disabled" : "filled"}
            loading={isLoadingCreate}
            onClick={onUpdateIngredient}
            disabled={isDisabled}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditIngredient;
