import { RecipeIcon } from "../../assets";
import styles from "./styles.module.scss";

type FoodImageProps = {
  image?: string;
};

const FoodImage: React.FC<FoodImageProps> = ({ image }) => {
  return (
    <div className={styles.wrapper}>
      {image ? <img src={image} alt="food" /> : <RecipeIcon />}
    </div>
  );
};

export default FoodImage;
