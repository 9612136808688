import { AxiosResponse } from "axios";
import axiosInstance from "../apis";
import { IUnit } from "../models";

export const getIngredients = (
  page: number,
  limit: number,
  search: string,
  sortValue?: "name" | "category" | "createdAt" | "updatedAt" | null,
  order?: -1 | 1 | null,
  categories?: string[]
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    let params: any = {
      page,
      limit,
    };

    if (search) {
      params.value = search;
    }
    if (sortValue) {
      params.sortValue = sortValue;
    }

    if (order) {
      params.order = order === -1 ? "asc" : "desc";
    }

    if (categories?.length) {
      params.categories = categories?.join(",");
    }

    axiosInstance
      .get("admin/ingredient/search", { params })
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createIngredient = (
  name: string,
  category: string,
  units: IUnit[],
  brand?: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      name,
      category,
      units,
      brand,
    };
    axiosInstance
      .post("admin/ingredient", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const updateIngredient = (
  name: string,
  category: string,
  units: any[],
  id: number,
  brand?: string
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      name,
      category,
      units,
      ingredientId: id,
      brand,
    };
    axiosInstance
      .put("admin/ingredient", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getIngredientById = (id: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/ingredient/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const deleteIngredient = (id: number): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/ingredient/id/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
