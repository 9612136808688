import React, { HtmlHTMLAttributes } from "react";
import styles from "./styles.module.scss";

interface CircleButtonProps extends HtmlHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
}

const CircleButton: React.FC<CircleButtonProps> = ({ icon, ...props }) => {
  return (
    <button {...props} className={styles.wrapper}>
      {icon}
    </button>
  );
};

export default CircleButton;
