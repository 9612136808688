import { RunningIcon } from "../../assets";
import styles from "./styles.module.scss";

const ActivityIcon = () => {
  return (
    <div className={styles.wrapper}>
      <RunningIcon />
    </div>
  );
};

export default ActivityIcon;
