import dayjs, { Dayjs } from "dayjs";
import { DayItem } from "../../components";
import styles from "./styles.module.scss";
import { IUser } from "../../models";
import React, { useMemo } from "react";
import { getDaysOfWeek } from "../../utils";
import { MealIntervalLog } from "../../services/users.service";

type WeeklyDaysProps = {
  date: Dayjs;
  setDate: (date: Dayjs) => void;
  user?: IUser;
  mealInterval?: MealIntervalLog[];
};

const WeeklyDays: React.FC<WeeklyDaysProps> = ({
  date,
  setDate,
  user,
  mealInterval,
}) => {
  const weekDays = useMemo(() => {
    return getDaysOfWeek(date.toDate());
  }, [date]);

  return (
    <div className={styles.wrapper}>
      {weekDays?.map((item, index) => {
        const dayInfo = mealInterval?.find((log) => {
          return log.date === item;
        });

        return (
          <DayItem
            date={dayjs.utc(item).toDate()}
            isCompleted={!!dayInfo?.isDayCompleted}
            isDisabled={!dayInfo}
            isSelected={item === date.toISOString()?.split("T")[0]}
            onSelect={() => setDate(dayjs.utc(item))}
          />
        );
      })}
    </div>
  );
};

export default WeeklyDays;
