import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import {
  ActivitySection,
  Button,
  MealSection,
  WeightSection,
} from "../../components";
import CalorieGoal from "../CalorieGoal";
import WeeklyDeficit from "../WeeklyDeficit";
import DateRange from "../DateRange";
import WeeklyDays from "../WeekDays";
import DailyGoal from "../DailyGoal";
import dayjs from "dayjs";
import { IUser } from "../../models";
import { useQuery } from "@tanstack/react-query";
import { getMealIntervalLogs } from "../../services/users.service";
import ProgressDateRange from "../ProgressDateRange";
import { MonthlyDeficitChart, StreakConsistency } from "..";
import CalorieBurn from "../CalorieBurn";
import { PulseLoader } from "react-spinners";

const UserStats = ({ user }: { user: IUser }) => {
  const [activeTab, setActiveTab] = React.useState<"Meal Plan" | "Progress">(
    "Meal Plan"
  );

  const [activeDate, setActiveDate] = useState(dayjs().utc(true));
  const [progressDate, setProgressDate] = useState(dayjs().utc(true));

  const fromDate = activeDate.startOf("isoWeek").format("YYYY-MM-DD");
  const toDate = activeDate.endOf("isoWeek").format("YYYY-MM-DD");

  const { data: mealInterval, isPending } = useQuery({
    queryKey: ["meal-interval-log", user?.id, fromDate, toDate],
    queryFn: () => {
      return getMealIntervalLogs(user?.id || 0, fromDate, toDate);
    },
  });

  useEffect(() => {
    if (!activeDate.isToday() && mealInterval) {
      setActiveDate(dayjs.utc(mealInterval?.[0]?.date));
    }
  }, [mealInterval]);

  const currentMealLog = useMemo(() => {
    return mealInterval?.find((log) => {
      return log.date === activeDate.toISOString().split("T")[0];
    });
  }, [mealInterval, activeDate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.rowContainer}>
        <Button
          onClick={() => setActiveTab("Meal Plan")}
          size="x-small"
          title="Meal Plan"
          styleType={activeTab === "Meal Plan" ? "filled" : "inactive"}
        />
        <Button
          onClick={() => setActiveTab("Progress")}
          size="x-small"
          title="Progress"
          styleType={activeTab === "Progress" ? "filled" : "inactive"}
        />
      </div>

      <div className={styles.contentContainer}>
        {activeTab === "Meal Plan" ? (
          <>
            <div className={styles.container}>
              <DateRange
                date={activeDate}
                setDate={setActiveDate}
                user={user}
              />
              {isPending ? (
                <div className={styles.loaderContainer}>
                  <PulseLoader color={"#7D4AFB"} />
                </div>
              ) : (
                <>
                  <WeeklyDays
                    date={activeDate}
                    setDate={setActiveDate}
                    user={user}
                    mealInterval={mealInterval}
                  />
                  <DailyGoal mealLog={currentMealLog} />
                  <div className={styles.sectionsContainer}>
                    {currentMealLog?.sections?.map((section) => {
                      return (
                        <MealSection
                          section={section}
                          key={section.logSectionId}
                        />
                      );
                    })}
                  </div>
                  {currentMealLog?.activities?.length ? (
                    <ActivitySection activities={currentMealLog?.activities} />
                  ) : null}
                  {currentMealLog?.isLoggedWeight ? (
                    <WeightSection
                      weight={currentMealLog?.weight}
                      weightUnit={user?.weight?.unit}
                    />
                  ) : null}
                </>
              )}
            </div>
            {!isPending ? (
              <div className={styles.container}>
                <CalorieGoal
                  user={user}
                  mealLog={currentMealLog}
                  selectedWeekMealInterval={mealInterval}
                />
                <WeeklyDeficit selectedWeekMealInterval={mealInterval} />
                <CalorieBurn
                  date={activeDate}
                  user={user}
                  selectedWeekMealInterval={mealInterval}
                />
              </div>
            ) : null}
          </>
        ) : (
          <>
            <div className={styles.container}>
              <ProgressDateRange
                date={progressDate}
                setDate={setProgressDate}
                user={user}
              />
              <MonthlyDeficitChart user={user} date={progressDate} />
            </div>
            <div className={styles.rightContainer}>
              <StreakConsistency date={progressDate} user={user} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserStats;
