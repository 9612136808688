import React, { FC } from "react";
import { IProps } from "./types";
import styles from "./styles.module.scss";

const MacroIconItem: FC<IProps> = ({
  title,
  icon,
  iconBackgroundCOlor,
  value,
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.value}>{value}</span>
      <div className={styles.rowContainer}>
        <div
          style={{ backgroundColor: iconBackgroundCOlor }}
          className={styles.iconWrapper}
        >
          {icon}
        </div>

        <span className={styles.title}>{title}</span>
      </div>
    </div>
  );
};

export default MacroIconItem;
