import clsx from "clsx";
import styles from "./styles.module.scss";

interface LegendProps {
  label: string;
  color: string;
  colorOpacity: string;
  description: string;
  variant?: "border" | "line" | "half-fade";
}

const Legend: React.FC<LegendProps> = ({
  variant = "border",
  color,
  colorOpacity,
  label,
}) => {
  return (
    <div className={styles.chartItem}>
      {variant === "border" ? (
        <div
          style={{
            backgroundColor: colorOpacity,
            borderColor: color,
            borderWidth: 2,
          }}
          className={styles.chartItemDot}
        />
      ) : variant === "line" ? (
        <div
          style={{ overflow: "hidden", justifyContent: "center" }}
          className={styles.chartItemDot}
        >
          <div style={{ height: 2, width: "100%", backgroundColor: color }} />
        </div>
      ) : (
        <div className={styles.chartItemDotRotate}>
          <div style={{ flex: 1, backgroundColor: color }} />
          <div style={{ flex: 1, backgroundColor: colorOpacity }} />
        </div>
      )}
      <p className={styles.chartItemTitle}>{label}</p>
    </div>
  );
};

export default Legend;
