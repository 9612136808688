import { CalorieIcon, InIcon, OutIcon } from "../../assets";
import { formatNumberToLocaleString } from "../../utils/methods";
import MacroIconItem from "../MacroIconItem";
import styles from "./styles.module.scss";

type MacroEquationProps = {
  eat: number;
  burn: number;
};

const MacroEquation: React.FC<MacroEquationProps> = ({ eat, burn }) => {
  const deficit = burn - eat;

  return (
    <div className={styles.wrapper}>
      <MacroIconItem
        icon={<InIcon style={{ fill: "rgba(97, 42, 255, 1)" }} />}
        iconBackgroundCOlor="rgba(97, 42, 255, 0.2)"
        title="Eat"
        value={`${formatNumberToLocaleString(eat)} cal`}
      />
      <span>-</span>
      <MacroIconItem
        icon={<OutIcon style={{ fill: "rgba(255, 168, 0, 1)" }} />}
        iconBackgroundCOlor="rgba(255, 168, 0, 0.2)"
        title="Burn"
        value={`${formatNumberToLocaleString(burn)} cal`}
      />
      <span>=</span>

      <MacroIconItem
        icon={<CalorieIcon style={{ fill: "rgba(54, 217, 14,1)" }} />}
        iconBackgroundCOlor="rgba(54, 217, 14, 0.2)"
        title="Deficit"
        value={`${formatNumberToLocaleString(deficit)} cal`}
      />
    </div>
  );
};

export default MacroEquation;
