import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { PulseLoader } from "react-spinners";
import {
  Button,
  IngredientItem,
  IngredientsFilter,
  PaginationButtons,
  SearchInput,
} from "../../../components";
import { AddSquareIcon, ArrowSmallDownIcon, FilterIcon } from "../../../assets";
import clsx from "clsx";
import { getIngredients } from "../../../services/ingredients.service";
import { CreateIngredient } from "../../../modals";
import { useDebounce, useOutsideClick } from "../../../hooks";
import { IIngredient } from "../../../models";

const pageLimit = 20;

const Ingredients: FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const [ingredient, setIngredients] = useState<IIngredient[]>([]);
  const [search, setSearch] = useState<string>("");
  const debounceSearch = useDebounce(search, 600);
  const page = useRef<number>(1);
  const [pagesCount, setPageCount] = useState(0);
  const [nameSort, setNameSort] = useState<-1 | 1 | 0>(0);
  const [categorySort, setCategorySort] = useState<-1 | 1 | 0>(0);
  const [dateSort, setDateSort] = useState<-1 | 1 | 0>(0);
  const [updateSort, setUpdateSort] = useState<-1 | 1 | 0>(0);
  const [count, setCount] = useState(0);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filterSelectedCategories, setFilterSelectedCategories] = useState<
    string[]
  >([]);

  const filterRef = useRef<HTMLDivElement>(null);
  useOutsideClick(filterRef, () => setIsOpenFilter(false));

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    page.current = 1;
    setSearch(e.target.value);
  };

  const onGetIngredients = async () => {
    try {
      setIsLoading(true);

      const { data } = await getIngredients(
        page.current - 1,
        pageLimit,
        debounceSearch,
        nameSort !== 0
          ? "name"
          : categorySort !== 0
          ? "category"
          : dateSort !== 0
          ? "createdAt"
          : updateSort !== 0
          ? "updatedAt"
          : null,
        nameSort !== 0
          ? nameSort
          : categorySort !== 0
          ? categorySort
          : dateSort !== 0
          ? dateSort
          : updateSort !== 0
          ? updateSort
          : null,
        filterSelectedCategories
      );

      if (data.success) {
        setIngredients(data?.data?.ingredients);
        setPageCount(Math.ceil(data?.data?.count / pageLimit));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetIngredients();
  }, [
    debounceSearch,
    nameSort,
    categorySort,
    dateSort,
    updateSort,
    filterSelectedCategories,
  ]);

  return (
    <>
      <CreateIngredient
        isOpen={isOpenCreateModal}
        setIsOpen={setIsOpenCreateModal}
        onGetIngredients={onGetIngredients}
      />
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>
            Ingredients{" "}
            {!!count && <span className={styles.countRecipe}>({count})</span>}
          </h4>
          <div className={styles.headerAddButton}>
            <Button
              title={"Create New Ingredient"}
              leftIcon={<AddSquareIcon />}
              onClick={() => setIsOpenCreateModal(true)}
              styleType="filled"
              size="small"
            />
          </div>
        </header>
        <section className={styles.contentContainer}>
          <div className={styles.contentContainerHeader}>
            <div className={styles.searchContainer}>
              <SearchInput
                placeholder="Search by name"
                value={search}
                onChange={onChangeSearch}
              />
            </div>
            <div ref={filterRef}>
              <div
                onClick={() => setIsOpenFilter((prev) => !prev)}
                className={clsx(styles.filterButton, {
                  [styles.activeFilterButton]: isOpenFilter,
                })}
              >
                <FilterIcon />
              </div>
              {isOpenFilter && (
                <IngredientsFilter
                  filterSelectedCategories={filterSelectedCategories}
                  setFilterSelectedCategories={(categories) => {
                    page.current = 1;
                    setFilterSelectedCategories(categories);
                  }}
                  setIsOpen={setIsOpenFilter}
                />
              )}
            </div>
          </div>
          <div className={styles.sectionHeader}>
            <div
              onClick={() => {
                setCategorySort(0);
                setDateSort(0);
                setUpdateSort(0);
                setNameSort((prev) => {
                  if (prev === 0) {
                    return -1;
                  } else if (prev === -1) {
                    return 1;
                  } else return 0;
                });
              }}
              className={clsx(styles.nameBlock, {
                [styles.activeSortValue]: nameSort !== 0,
              })}
            >
              <p>Ingredient Name</p>{" "}
              <ArrowSmallDownIcon
                className={clsx(styles.sectionHeaderArrow, {
                  [styles.sectionHeaderArrowUp]: nameSort === -1,
                })}
              />
            </div>
            <div
              className={clsx(styles.categoryBlock, {
                [styles.activeSortValue]: categorySort !== 0,
              })}
            >
              <p>Category</p>{" "}
            </div>
            <div
              onClick={() => {
                setNameSort(0);
                setCategorySort(0);
                setDateSort(0);
                setUpdateSort((prev) => {
                  if (prev === 0) {
                    return -1;
                  } else if (prev === -1) {
                    return 1;
                  } else return 0;
                });
              }}
              className={clsx(styles.dateBlock, {
                [styles.activeSortValue]: updateSort !== 0,
              })}
            >
              <p>Date Updated</p>{" "}
              <ArrowSmallDownIcon
                className={clsx(styles.sectionHeaderArrow, {
                  [styles.sectionHeaderArrowUp]: updateSort === -1,
                })}
              />
            </div>
            <div
              onClick={() => {
                setNameSort(0);
                setCategorySort(0);
                setUpdateSort(0);

                setDateSort((prev) => {
                  if (prev === 0) {
                    return -1;
                  } else if (prev === -1) {
                    return 1;
                  } else return 0;
                });
              }}
              className={clsx(styles.dateBlock, {
                [styles.activeSortValue]: dateSort !== 0,
              })}
            >
              <p>Date Created</p>{" "}
              <ArrowSmallDownIcon
                className={clsx(styles.sectionHeaderArrow, {
                  [styles.sectionHeaderArrowUp]: dateSort === -1,
                })}
              />
            </div>
          </div>
          <div className={styles.listContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#7D4AFB"} />
              </div>
            )}
            {!isLoading &&
              ingredient.map((item, index) => (
                <IngredientItem
                  item={item}
                  key={item?.id}
                  searchWord={debounceSearch}
                  onGetIngredients={onGetIngredients}
                />
              ))}
            {!isLoading && ingredient?.length === 0 && (
              <p className={styles.emptyTitle}>Ingredients not found</p>
            )}
          </div>
          <PaginationButtons
            pagesCount={pagesCount}
            page={page.current}
            onPress={(index) => {
              page.current = index;
              onGetIngredients();
            }}
          />
        </section>
      </div>
    </>
  );
};

export default Ingredients;
