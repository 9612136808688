import { CalorieIcon, MealIcon } from "../../assets";
import { CircleProgress } from "../../components";
import { MealIntervalLog } from "../../services/users.service";
import { formatNumberToLocaleString, getPercentage } from "../../utils/methods";
import styles from "./styles.module.scss";

interface DailyGoalProps {
  mealLog?: MealIntervalLog;
}

const DailyGoal: React.FC<DailyGoalProps> = ({ mealLog }) => {
  const loggedIntake = mealLog?.total.calories || 0;
  const expectedIntake = mealLog?.expected.intake || 0;
  const loggedDeficit = mealLog?.loggedValues.deficit || 0;
  const expectedDeficit = mealLog?.expected.calorieDeficitPerDay || 0;

  const intakeProgress = getPercentage(loggedIntake, expectedIntake);

  const deficitProgress = getPercentage(loggedDeficit, expectedDeficit);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <CircleProgress
          value={intakeProgress / 100}
          title=""
          trailColor="rgba(97, 42, 255, 0.2)"
          pathColor="rgba(97, 42, 255, 1)"
          width={48}
          height={48}
          icon={<MealIcon style={{ fill: "#612AFF", width: 20, height: 20 }} />}
        />
        <div className={styles.valuesContainer}>
          <p>
            {formatNumberToLocaleString(loggedIntake, 1)} /{" "}
            {formatNumberToLocaleString(expectedIntake, 1)}
          </p>
          <span>Intake</span>
        </div>
      </div>
      <div className={styles.container}>
        <CircleProgress
          value={deficitProgress / 100}
          title=""
          trailColor="rgba(54, 217, 14, 0.2)"
          pathColor="rgba(54, 217, 14, 1)"
          width={48}
          height={48}
          icon={
            <CalorieIcon
              style={{ fill: "rgba(54, 217, 14, 1", width: 20, height: 20 }}
            />
          }
        />
        <div className={styles.valuesContainer}>
          <p>
            {formatNumberToLocaleString(loggedDeficit, 1)} /{" "}
            {formatNumberToLocaleString(expectedDeficit, 1)}
          </p>
          <span>Deficit</span>
        </div>
      </div>
    </div>
  );
};

export default DailyGoal;
