import { Dayjs } from "dayjs";
import styles from "./styles.module.scss";
import { EACTIVITY_LEVEL, IUser } from "../../models";
import {
  getUserCalorieBurn,
  MealIntervalLog,
} from "../../services/users.service";
import { useQuery } from "@tanstack/react-query";
import { getDaysOfWeek } from "../../utils";
import { useMemo } from "react";
import { formatNumberToLocaleString } from "../../utils/methods";
import {
  Bar,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryScatter,
  VictoryStack,
  VictoryVoronoiContainer,
} from "victory";
import { COLORS } from "../../constants";
import React from "react";
import { BarPathComponent, Legend } from "../../components";

type CalorieBurnProps = {
  date: Dayjs;
  user: IUser;
  selectedWeekMealInterval?: MealIntervalLog[];
};

const CalorieBurn: React.FC<CalorieBurnProps> = ({
  user,
  date,
  selectedWeekMealInterval,
}) => {
  const from = date.startOf("isoWeek");
  const to = date.endOf("isoWeek");

  const { data: trackerData } = useQuery({
    queryKey: ["calorie-burn", user?.id, from, to],
    queryFn: async () => {
      return await getUserCalorieBurn(
        user?.id || 0,
        from?.toISOString()?.split("T")[0],
        to?.toISOString()?.split("T")[0]
      );
    },
    enabled: !!user?.tracker,
  });

  const weekDays = getDaysOfWeek(date.toDate());

  const chartData = useMemo(() => {
    return weekDays.map((date) => {
      const dailyData = selectedWeekMealInterval?.find(
        (item) => item?.date === date
      );
      console.log(dailyData?.manualDataUsed);
      return {
        date: date?.toString(),
        BMR: +(dailyData?.loggedValues?.BMR?.toFixed(0) || 0),
        NEAT: +(dailyData?.loggedValues?.NEAT?.toFixed(0) || 0),
        exercise: +(dailyData?.loggedValues?.activityCalories?.toFixed(0) || 0),
        total: +(
          (dailyData?.loggedValues?.BMR || 0) +
          (dailyData?.loggedValues?.NEAT || 0) +
          (dailyData?.loggedValues?.activityCalories || 0)
        )?.toFixed(0),
        manualDataUsed: dailyData?.manualDataUsed,
      };
    });
  }, [user, trackerData, selectedWeekMealInterval, weekDays]);

  const totalBurned = useMemo(() => {
    return (
      selectedWeekMealInterval?.reduce((acc, cur) => {
        return (
          acc +
          (cur?.loggedValues?.BMR || 0) +
          (cur?.loggedValues?.NEAT || 0) +
          (cur?.loggedValues?.activityCalories || 0)
        );
      }, 0) || 0
    );
  }, [user, selectedWeekMealInterval]);

  const manualDataUsed = useMemo(() => {
    return chartData?.filter((item) => item.manualDataUsed);
  }, [chartData]);
  console.log("manualDataUsed", manualDataUsed);

  const totalExercise = useMemo(() => {
    return chartData?.reduce((acc, item) => {
      return acc + item.exercise;
    }, 0);
  }, [chartData]);

  const totalManual = useMemo(() => {
    return chartData?.reduce((acc, item) => {
      return acc + item.BMR + item.NEAT;
    }, 0);
  }, [chartData]);

  const percentExercise = useMemo(() => {
    return (totalExercise / totalBurned) * 100;
  }, [totalExercise, totalBurned]);

  const percentManual = useMemo(() => {
    return (totalManual / totalBurned) * 100;
  }, [totalManual, totalBurned]);

  const maxValue = chartData?.reduce((acc, item) => {
    return Math.max(acc, item.BMR + item.NEAT + item.exercise);
  }, 0);

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Calorie Burn Data</p>
      <p className={styles.infoTitle}>
        Method: <span>{user?.tracker ? "Tracker" : "Manual"}</span>
      </p>
      {user?.anotherTrackers?.length ? (
        <p className={styles.infoTitle}>
          Alt Tracker: <span>{user?.anotherTrackers?.join(", ")}</span>
        </p>
      ) : null}
      {user?.howOftenWearsTracker ? (
        <p className={styles.infoTitle}>
          How often wears tracker: <span>{user?.howOftenWearsTracker}</span>
        </p>
      ) : null}
      <p className={styles.infoTitle}>
        Lifestyle:{" "}
        <span>
          {EACTIVITY_LEVEL.SEDENTARY === user?.neatFactor
            ? 1
            : EACTIVITY_LEVEL.LIGHTLY_ACTIVE === user?.neatFactor
            ? 2
            : EACTIVITY_LEVEL.MODERATELY_ACTIVE === user?.neatFactor
            ? 3
            : EACTIVITY_LEVEL.VERY_ACTIVE === user?.neatFactor
            ? 4
            : 5}
          {"-"}
          {user?.neatFactor}
        </span>
      </p>

      <div className={styles.chartContainer}>
        <div className={styles.headerValues}>
          <div className={styles.block}>
            <p className={styles.weight}>
              {formatNumberToLocaleString(totalBurned || 0)} <span>cal</span>
            </p>

            <p className={styles.title}>
              Total calories burn over
              <br /> the week
            </p>
          </div>
        </div>

        <VictoryChart
          maxDomain={{ y: maxValue === 0 ? 600 : maxValue + maxValue * 0.2 }}
          padding={{ left: 35, top: 15, bottom: 15, right: 0 }}
          domainPadding={{ x: [60, 60] }}
          width={429}
          height={228}
          containerComponent={<VictoryVoronoiContainer />}
        >
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            style={{
              tickLabels: {
                fontSize: 9,
                padding: 4,
                fill: COLORS.darkGray,
              },
              grid: { stroke: COLORS.lightGray },
              axis: { stroke: "transparent" },
            }}
          />
          <VictoryAxis
            dependentAxis={false}
            tickFormat={() => {
              return "";
            }}
            style={{
              axis: {
                stroke: COLORS.lightGray,
              },
            }}
          />

          {chartData?.map((item, index) => {
            return (
              <VictoryGroup key={index}>
                <VictoryScatter
                  dataComponent={<></>}
                  labelComponent={
                    <VictoryLabel
                      style={{
                        fontSize: 10,
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    />
                  }
                  labels={(props) => {
                    if (props?.datum?.total && props.datum.y > 1) {
                      return props.datum.y;
                    }
                  }}
                  data={[
                    {
                      x: item.date,
                      y: item.total,
                      y0: item.total,
                      total: true,
                    },
                  ]}
                />

                <VictoryStack>
                  <VictoryBar
                    barWidth={32}
                    style={{
                      data: {
                        fill: ({ datum }) =>
                          datum.manualDataUsed
                            ? COLORS.purpleOpacity20
                            : COLORS.orangeOpacity20,
                        stroke: COLORS.orange,
                        strokeWidth: (data) => {
                          return data?.datum?.y ? 3 : 0;
                        },
                      },
                    }}
                    labels={({ datum }) => datum.y}
                    labelComponent={
                      <VictoryLabel
                        dy={(data) => {
                          const getCenteredY = (data: any) => {
                            let y =
                              ((228 - data?.y) / data.datum._y1) *
                              (data.datum.y / 2);

                            return y + 4.5;
                          };
                          return getCenteredY(data);
                        }}
                        style={{
                          fontSize: 10,
                          fontFamily: "Inter",
                          fontWeight: 500,
                        }}
                      />
                    }
                    dataComponent={
                      <Bar
                        pathComponent={React.createElement(BarPathComponent)}
                      />
                    }
                    cornerRadius={{
                      top: () => 3,
                    }}
                    data={[
                      {
                        x: item.date,
                        y: item.BMR,
                        manualDataUsed: item.manualDataUsed,
                      },
                    ]}
                  />
                  <VictoryBar
                    barWidth={32}
                    style={{
                      data: {
                        fill: ({ datum }) =>
                          datum.manualDataUsed
                            ? COLORS.purpleOpacity20
                            : COLORS.brownOpacity20,
                        stroke: COLORS.brown,
                        strokeWidth: (data) => {
                          return data?.datum?.y ? 3 : 0;
                        },
                      },
                    }}
                    labels={({ datum }) => datum.y}
                    labelComponent={
                      <VictoryLabel
                        dy={(data) => {
                          const getCenteredY = (data: any) => {
                            let y =
                              ((228 - data?.y) / data.datum._y1) *
                              (data.datum.y / 2);

                            return y + 4.5;
                          };
                          return getCenteredY(data);
                        }}
                        style={{
                          fontSize: 10,
                          fontFamily: "Inter",
                          fontWeight: 500,
                        }}
                      />
                    }
                    dataComponent={
                      <Bar
                        pathComponent={React.createElement(BarPathComponent)}
                      />
                    }
                    cornerRadius={{
                      top: () => 3,
                    }}
                    data={[
                      {
                        x: item.date,
                        y: item.NEAT,
                        y0: item.BMR,
                        manualDataUsed: item.manualDataUsed,
                      },
                    ]}
                  />
                  <VictoryBar
                    barWidth={32}
                    style={{
                      data: {
                        fill: ({ datum }) =>
                          datum.manualDataUsed
                            ? COLORS.purpleOpacity20
                            : COLORS.springGreenOpacity20,
                        stroke: COLORS.springGreen,
                        strokeWidth: (data) => {
                          return data?.datum?.y ? 3 : 0;
                        },
                      },
                    }}
                    labels={({ datum }) => {
                      if (datum.y > 50) {
                        return datum.y;
                      }
                    }}
                    labelComponent={
                      <VictoryLabel
                        dy={(data) => {
                          const getCenteredY = (data: any) => {
                            let y =
                              ((228 - data?.y) / data.datum._y1) *
                              (data.datum.y / 2);

                            return y + 4.5;
                          };
                          return getCenteredY(data);
                        }}
                        style={{
                          fontSize: 10,
                          fontFamily: "Inter",
                          fontWeight: 500,
                        }}
                      />
                    }
                    dataComponent={
                      <Bar
                        pathComponent={React.createElement(BarPathComponent)}
                      />
                    }
                    cornerRadius={{
                      top: () => 3,
                    }}
                    data={[
                      {
                        x: item.date,
                        y: item.exercise,
                        y0: item.BMR + item.NEAT,
                        manualDataUsed: item.manualDataUsed,
                      },
                    ]}
                  />
                  <VictoryScatter
                    labels={() => ""}
                    dataComponent={<InfoIcon />}
                    data={[
                      {
                        x: item.date,
                        y: item.manualDataUsed ? 11 : 0,
                        y0: item.manualDataUsed ? 10 : 0,
                      },
                    ]}
                  />
                </VictoryStack>
              </VictoryGroup>
            );
          })}
        </VictoryChart>

        <div className={styles.footerChart}>
          <Legend
            label={"BMR"}
            description="Calories burn when your intake is less than your caloric expenditure."
            colorOpacity={COLORS.orange}
            color={COLORS.orange}
            variant="border"
          />

          <Legend
            label={"NEAT"}
            description="Your calorie deficit goal."
            color={COLORS.brown}
            colorOpacity={COLORS.brown}
          />
          <Legend
            label={"Exercise"}
            description="Your calorie deficit goal."
            color={COLORS.springGreen}
            colorOpacity={COLORS.springGreen}
          />
          <Legend
            label={"Manual"}
            description="Your calorie deficit goal."
            color={COLORS.purpleOpacity20}
            colorOpacity={COLORS.purpleOpacity20}
          />
        </div>

        <div className={styles.headerValues}>
          <div className={styles.block}>
            <p className={styles.weight}>
              {formatNumberToLocaleString(totalExercise)} <span>cal</span>
            </p>

            <p className={styles.title}>
              <div
                style={{
                  borderColor: COLORS.springGreen,
                  backgroundColor: COLORS.springGreenOpacity20,
                }}
                className={styles.indicator}
              />
              Total calories burnt from <br /> exercise over the week
            </p>
            <p className={styles.title}>
              <span style={{ color: COLORS.springGreen, fontWeight: 600 }}>
                {formatNumberToLocaleString(percentExercise)}%
              </span>{" "}
              of total calories
            </p>
          </div>
          <div className={styles.block}>
            <p className={styles.weight}>
              {formatNumberToLocaleString(totalManual)} <span>cal</span>
            </p>

            <p className={styles.title}>
              <div
                style={{
                  borderColor: COLORS.orange,
                  backgroundColor: COLORS.orangeOpacity20,
                }}
                className={styles.indicator}
              />
              Total non-exercise calories <br /> burn over the week
            </p>
            <p className={styles.title}>
              <span style={{ color: COLORS.orange, fontWeight: 600 }}>
                {formatNumberToLocaleString(percentManual)}%
              </span>{" "}
              of total calories
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoIcon: any = (props: any) => {
  if (props?.datum?.y > 0) {
    return (
      <svg
        x={props.x - 10}
        y={props.y - 25}
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <path
          d="M10.5 1.75C8.76942 1.75 7.07769 2.26318 5.63876 3.22464C4.19983 4.1861 3.07832 5.55267 2.41606 7.15152C1.75379 8.75037 1.58051 10.5097 1.91813 12.207C2.25575 13.9044 3.08911 15.4635 4.31282 16.6872C5.53653 17.9109 7.09563 18.7443 8.79296 19.0819C10.4903 19.4195 12.2496 19.2462 13.8485 18.5839C15.4473 17.9217 16.8139 16.8002 17.7754 15.3612C18.7368 13.9223 19.25 12.2306 19.25 10.5C19.2474 8.18015 18.3247 5.95605 16.6843 4.31567C15.044 2.67528 12.8199 1.75258 10.5 1.75ZM10.5 17.5C9.11553 17.5 7.76216 17.0895 6.61101 16.3203C5.45987 15.5511 4.56266 14.4579 4.03285 13.1788C3.50303 11.8997 3.36441 10.4922 3.63451 9.13437C3.9046 7.7765 4.57129 6.52922 5.55026 5.55025C6.52922 4.57129 7.7765 3.9046 9.13437 3.6345C10.4922 3.36441 11.8997 3.50303 13.1788 4.03284C14.4579 4.56266 15.5511 5.45986 16.3203 6.61101C17.0895 7.76215 17.5 9.11553 17.5 10.5C17.4979 12.3559 16.7597 14.1351 15.4474 15.4474C14.1351 16.7597 12.3559 17.4979 10.5 17.5ZM10.5 10.0625C10.2679 10.0625 10.0454 10.1547 9.88129 10.3188C9.71719 10.4829 9.625 10.7054 9.625 10.9375V13.5625C9.625 13.7946 9.71719 14.0171 9.88129 14.1812C10.0454 14.3453 10.2679 14.4375 10.5 14.4375C10.7321 14.4375 10.9546 14.3453 11.1187 14.1812C11.2828 14.0171 11.375 13.7946 11.375 13.5625V10.9375C11.375 10.7054 11.2828 10.4829 11.1187 10.3188C10.9546 10.1547 10.7321 10.0625 10.5 10.0625ZM10.5 6.5625C10.2837 6.5625 10.0722 6.62665 9.89235 6.74683C9.71248 6.86701 9.57229 7.03783 9.48951 7.23769C9.40673 7.43755 9.38507 7.65746 9.42727 7.86963C9.46947 8.0818 9.57364 8.27668 9.72661 8.42965C9.87957 8.58261 10.0745 8.68678 10.2866 8.72898C10.4988 8.77119 10.7187 8.74953 10.9186 8.66674C11.1184 8.58396 11.2892 8.44377 11.4094 8.26391C11.5296 8.08404 11.5938 7.87257 11.5938 7.65625C11.5938 7.36617 11.4785 7.08797 11.2734 6.88285C11.0683 6.67773 10.7901 6.5625 10.5 6.5625Z"
          fill="#612AFF"
        />
      </svg>
    );
  }
};

export default CalorieBurn;
