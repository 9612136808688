// react
import { useState, useEffect } from "react";

// libs
import dayjs from "dayjs";

// types
import { IUser } from "../models";

const useGetAvailableDays = (user?: IUser) => {
  const [days, setDays] = useState<string[]>([]);
  const [initialDayIndex, setInitialDayIndex] = useState<number>(0);

  useEffect(() => {
    if (user) {
      const fromDate = dayjs.utc(user?.onboardingAt);
      const toDate = dayjs.utc(user?.last?.lastGeneratedLog?.date);

      const days: string[] = [];

      let currentDay = fromDate;

      while (
        currentDay.isBefore(toDate, "day") ||
        currentDay.isSame(toDate, "day")
      ) {
        days.push(currentDay.toISOString().split("T")[0]);
        currentDay = currentDay.add(1, "day");
      }

      let currentWeekIndex = 0;

      setInitialDayIndex(currentWeekIndex);
      setDays(days);
    }
  }, [user]);

  return {
    days,
    initialDayIndex,
  };
};

export default useGetAvailableDays;
