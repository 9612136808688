import React from "react";
import styles from "./styles.module.scss";
import { Button, CircleButton, DateSelector } from "../../components";
import { ArrowDownIcon } from "../../assets";
import dayjs, { Dayjs } from "dayjs";
import { IUser } from "../../models";

type DateRangeProps = {
  date: Dayjs;
  setDate: (date: Dayjs) => void;
  user: IUser;
};

const DateRange: React.FC<DateRangeProps> = ({ date, setDate, user }) => {
  const leftDisabled = date
    .startOf("isoWeek")
    .subtract(1, "day")
    .isBefore(user?.onboardingAt, "day");

  const rightDisabled = date
    .endOf("isoWeek")
    .add(1, "day")
    .isAfter(user?.last?.lastGeneratedLog?.date, "day");

  const onPrevMonth = () => {
    if (leftDisabled) {
      return;
    }
    setDate(date.subtract(1, "week"));
  };

  const onNextMonth = () => {
    if (rightDisabled) {
      return;
    }
    setDate(date.add(1, "week"));
  };

  return (
    <div className={styles.wrapper}>
      <CircleButton
        onClick={onPrevMonth}
        icon={<ArrowDownIcon className={styles.leftArrow} />}
      />
      <CircleButton
        onClick={onNextMonth}
        icon={<ArrowDownIcon className={styles.rightArrow} />}
      />
      <Button
        size="x-small"
        styleType="outlined"
        title="Today"
        color={"#7D4AFB"}
        customStyles={styles.todayButton}
        onClick={() => setDate(dayjs().utc(true))}
      />

      <DateSelector date={date} setDate={setDate} user={user} />
    </div>
  );
};

export default DateRange;
