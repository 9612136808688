import { IUser } from "../../models";
import UserDailyDeficit from "../UserDailyDeficit";
import UserFatLoss from "../UserFatLoss";
import styles from "./styles.module.scss";

type UserGoalProps = {
  user: IUser;
};

const UserGoal: React.FC<UserGoalProps> = ({ user }) => {
  return (
    <div className={styles.wrapper}>
      <UserFatLoss user={user} />
      <UserDailyDeficit user={user} />
    </div>
  );
};

export default UserGoal;
