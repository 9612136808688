import { CircularProgressbar } from "react-circular-progressbar";
import styles from "./styles.module.scss";
import { TrophyIcon } from "../../assets";

type CircleProgressProps = {
  value: number;
  title: string;
  trailColor?: string;
  pathColor?: string;
  icon?: React.ReactNode;
  width?: number;
  height?: number;
};

const CircleProgress: React.FC<CircleProgressProps> = ({
  value,
  title,
  trailColor = "rgba(217, 224, 233, 1)",
  pathColor = "rgba(54, 217, 14, 1)",
  icon,
  width = 56,
  height = 47,
}) => {
  return (
    <div className={styles.wrapper}>
      <div
        style={{
          width: `${width}px`,

          height: `${height}px`,
        }}
        className={styles.circleContainer}
      >
        <CircularProgressbar
          value={value}
          maxValue={1}
          circleRatio={0.65}
          styles={{
            trail: {
              stroke: trailColor,
            },
            path: {
              stroke: pathColor,
            },
          }}
          className={styles.circle}
        />
        <div
          style={{ top: title ? "60%" : "50%" }}
          className={styles.iconWrapper}
        >
          {icon ?? <TrophyIcon style={{ width: 24, height: 24 }} />}
        </div>
      </div>
      <span>{title}</span>
    </div>
  );
};

export default CircleProgress;
