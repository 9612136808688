import StatusBadge from "../StatusBadge";
import styles from "./styles.module.scss";

type LoggedContainerProps = {
  order: "first" | "last" | "middle" | "single";
  children: React.ReactNode;
  status: "logged" | "planned";
};

const LoggedContainer: React.FC<LoggedContainerProps> = ({
  order,
  children,
  status,
}) => {
  const bordersStyle =
    order === "single"
      ? {
          borderRadius: 16,
        }
      : order === "first"
      ? {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderBottomWidth: 0,
        }
      : order === "last"
      ? {
          borderTopWidth: 0,
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
        }
      : {
          borderTopWidth: 0,
          borderBottomWidth: 0,
        };

  return (
    <div
      style={{
        ...bordersStyle,
        ...{
          borderColor:
            status === "logged"
              ? "rgba(54, 217, 14,1)"
              : "rgba(245, 247, 250, 1)",
          backgroundColor:
            status === "logged" ? "rgba(54, 217, 14, 0.1)" : "white",
        },
      }}
      className={styles.wrapper}
    >
      <StatusBadge status={status} />
      {children}
    </div>
  );
};

export default LoggedContainer;
