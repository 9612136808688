import React from "react";
import styles from "./styles.module.scss";
import { EyeCloseIcon, EyeOpenIcon } from "../../assets";

type HiddenBlockProps = {
  title: string;
  value: string;
  width?: number;
  isHiddenDefault?: boolean;
};

const HiddenBlock: React.FC<HiddenBlockProps> = ({
  title,
  value,
  width = 130,
  isHiddenDefault = true,
}) => {
  const [isHidden, setIsHidden] = React.useState(isHiddenDefault);

  return (
    <div
      onClick={() => setIsHidden((prev) => !prev)}
      className={styles.wrapper}
      style={{ width }}
    >
      <span className={styles.title}>{title}</span>
      <div className={styles.row}>
        {isHidden ? (
          <span className={styles.hiddenTitle}>Hidden</span>
        ) : (
          <span className={styles.valueTitle}>{value}</span>
        )}
        {isHidden ? <EyeOpenIcon /> : <EyeCloseIcon />}
      </div>
    </div>
  );
};

export default HiddenBlock;
