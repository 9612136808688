import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  deleteUser,
  generateUserLogs,
  getUserById,
  unBlockUser,
} from "../../../services/users.service";
import { IUser } from "../../../models";
import { PulseLoader } from "react-spinners";
import { BlockUser, DeleteUser } from "../../../modals";
import { UserGoal, UserInfo, UserStats } from "../../../widgets";
import { ArrowLineIcon } from "../../../assets";
import { Button } from "../../../components";

const UserProfile: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams();

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [userProfile, setUserProfile] = useState<IUser>();
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isShowBlockModal, setIsShowBlockModal] = useState(false);
  const [isLoadingUnblock, setIsLoadingUnblock] = useState(false);
  const [isLoadingGenerateLogs, setIsLoadingGenerateLogs] = useState(false);

  const onGetUserProfile = async (id: string) => {
    try {
      setIsLoadingProfile(true);
      const { data } = await getUserById(id);
      if (data?.data) {
        setUserProfile(data?.data);
      }
    } catch (error) {
      toast.error(error?.message?.[0]?.message || "Something went wrong");
    } finally {
      setIsLoadingProfile(false);
    }
  };

  const onDeleteUser = async (id: number) => {
    try {
      setIsLoadingDelete(true);

      const { data } = await deleteUser(id);
      if (data?.success) {
        toast.success("User was successfully deleted");
        navigation(-1);
      }
    } catch (error) {
      toast.error(error?.message?.[0]?.message || "Something went wrong");
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const onGenerateUserLogs = async (id: number) => {
    try {
      setIsLoadingGenerateLogs(true);

      const { data } = await generateUserLogs(id);
      if (data?.message === "Ok") {
        toast.success("Logs were successfully generated");
      }
    } catch (error) {
      toast.error(error?.message?.[0]?.message || "Something went wrong");
    } finally {
      setIsLoadingGenerateLogs(false);
    }
  };

  const onUnblockUser = async (id: number) => {
    try {
      setIsLoadingUnblock(true);
      const { data } = await unBlockUser(id);
      if (data?.success) {
        toast.success("User was successfully unblocked");
        onGetUserProfile(id?.toString());
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    } finally {
      setIsLoadingUnblock(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      onGetUserProfile(params.id);
    }
  }, [params?.id]);

  return (
    <>
      <BlockUser
        isOpen={isShowBlockModal}
        setIsOpen={setIsShowBlockModal}
        userId={userProfile?.id || 0}
        onGetUserInfo={() => onGetUserProfile(params?.id || "")}
      />
      <DeleteUser
        isOpen={isShowDelete}
        setIsOpen={setIsShowDelete}
        onDelete={() => onDeleteUser(userProfile?.id || 0)}
        isLoading={isLoadingDelete}
        title="Are you sure you want to delete this user?"
      />
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <ArrowLineIcon
            onClick={() => navigation(-1)}
            className={styles.headerArrowButton}
          />
          <h5 className={styles.headerTitle}>User Profile</h5>
          <div className={styles.headerButtonsContainer}>
            <Button
              title={"Generate Logs"}
              styleType={"disabled"}
              size="small"
              customStyles={styles.generateLogButton}
              loading={isLoadingGenerateLogs}
              onClick={() => onGenerateUserLogs(userProfile?.id || 0)}
            />
            <Button
              title={userProfile?.authData?.isBlocked ? "Unblock" : "Block"}
              styleType={"disabled"}
              size="small"
              customStyles={styles.headerButton}
              loading={isLoadingUnblock}
              onClick={() => {
                if (userProfile?.authData?.isBlocked) {
                  onUnblockUser(userProfile?.id);
                } else {
                  setIsShowBlockModal(true);
                }
              }}
            />
            <Button
              title="Delete"
              styleType="filled"
              backgroundColor="#FF3C3C"
              size="small"
              customStyles={styles.headerButton}
              onClick={() => setIsShowDelete(true)}
            />
          </div>
        </header>
        {isLoadingProfile || !userProfile ? (
          <div className={styles.loaderWrapper}>
            <PulseLoader color={"#7D4AFB"} />
          </div>
        ) : (
          <>
            <UserInfo user={userProfile} />
            <UserGoal user={userProfile} />
            <UserStats user={userProfile} />
          </>
        )}
      </div>
    </>
  );
};

export default UserProfile;
