import { useQuery } from "@tanstack/react-query";
import MacroEquation from "../../components/MacroEquation";
import { IUser } from "../../models";
import styles from "./styles.module.scss";
import { getUserCalorieGoal } from "../../services/users.service";
import dayjs from "dayjs";

type UserDailyDeficitProps = {
  user: IUser;
};

const UserDailyDeficit: React.FC<UserDailyDeficitProps> = ({ user }) => {
  const { data } = useQuery({
    queryKey: ["user-calorie-goal", user?.id],
    queryFn: () => {
      return getUserCalorieGoal(user?.id);
    },
  });

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Daily Deficit</p>
      <p className={styles.updatedDate}>
        Updated:{" "}
        {dayjs
          .utc(user?.lastRecommendation?.date || user?.onboardingAt)
          .format("MM/DD/YYYY")}
      </p>
      <div>
        <p className={styles.infoTitle}>
          Method:{" "}
          <span>
            {user?.tracker && data?.hasCustomMacros
              ? "Custom Intake - Tracker"
              : user?.tracker && !data?.hasCustomMacros
              ? "Standard - Tracker"
              : !user?.tracker && data?.hasCustomMacros
              ? "Custom Intake - Manual"
              : "Standard - Manual"}
          </span>
        </p>
        <p className={styles.infoTitle}>
          Pace: <span>{user?.weightLossMode}</span>
        </p>
      </div>
      <MacroEquation
        eat={data?.recommendedDailyIntake || 0}
        burn={data?.tdee || 0}
      />
    </div>
  );
};

export default UserDailyDeficit;
