// react
import { FC, useEffect } from "react";

interface BarPathComponentProps {
  x: number;
  y: number;
  active?: boolean;
  onGetPosition?: (x: number, y: number) => void;
}

const BarPathComponent: FC<BarPathComponentProps> = ({
  y,
  x,
  onGetPosition,
  active,
  ...props
}) => {
  useEffect(() => {
    if (onGetPosition) {
      onGetPosition(x, y);
    }
  }, [x, y]);

  return <path {...props} />;
};

export default BarPathComponent;
