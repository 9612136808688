// react
import { FC } from "react";

// types
import { COLORS } from "../../constants";

interface StripesPathComponentProps {
  x: number;
  y: number;
  color: string;
  opacityColor: string;
  datum: {
    isNonCompletedDay?: boolean;
    date: string;
  };
}

const StripesPathComponent: FC<StripesPathComponentProps> = ({
  y,
  x,
  datum,
  ...props
}) => {
  {
    /* <defs>
        <pattern
          id={id}
          viewBox="0 0 8 8"
          width="8"
          height="8"
          patternUnits="userSpaceOnUse"
        >
          <polygon points="0,0 4,0 0,4" fill={fillOpacity} />
          <polygon points="0,8 8,0 8,4 4,8" fill={fillOpacity} />
          <polygon points="0,4 0,8 8,0 4,0" fill={fill} />
          <polygon points="4,8 8,8 8,4" fill={fill} />
        </pattern>
      </defs> */
  }

  return (
    <path
      //   d="M 10 10 H 90 V 90 H 10 Z"
      {...props}
    />
  );
};

export default StripesPathComponent;
