import dayjs, { Dayjs } from "dayjs";
import styles from "./styles.module.scss";
import { CalendarCheckIcon, ConsistencyIcon } from "../../assets";
import clsx from "clsx";
import { IUser } from "../../models";
import { useQuery } from "@tanstack/react-query";
import { getUserConsistencyStreak } from "../../services/users.service";
import { PulseLoader } from "react-spinners";
import { formatNumberToLocaleString } from "../../utils/methods";
import { COLORS } from "../../constants";
import { useMemo } from "react";
import { Legend } from "../../components";

type StreakConsistencyProps = {
  date: Dayjs;
  user: IUser;
};

const StreakConsistency: React.FC<StreakConsistencyProps> = ({
  date,
  user,
}) => {
  const { data, isPending } = useQuery({
    queryKey: ["streak", user?.id, date],
    queryFn: async () => {
      return await getUserConsistencyStreak(user.id, date.format("YYYY-MM-DD"));
    },
  });

  interface Day {
    date: string;
    goal: boolean;
    isDayCompleted: boolean;
    completionType: string;
    expectedDeficit: number;
    inStreak: boolean;
    isInGoal: boolean;
  }

  function getCalendarStyles(days: Day[]) {
    const sortedDays = days.sort((a, b) =>
      dayjs.utc(a.date).isBefore(b.date) ? -1 : 1
    );

    const styles: Record<
      string,
      {
        container: Record<string, string | number>;
        text: Record<string, string | number>;
      }
    > = {};

    const getBackgroundColor = (day: Day): string => {
      if (dayjs.utc(day.date).isAfter(dayjs().utc(true), "day")) {
        return "white";
      }

      if (dayjs.utc(day.date).isToday() && !day.isDayCompleted) {
        return COLORS.purple;
      }

      if (
        dayjs.utc(day.date).isToday() &&
        day.isDayCompleted &&
        day.completionType === "Manual" &&
        day.isInGoal
      ) {
        return COLORS.green;
      }
      if (
        !dayjs.utc(day.date).isToday() &&
        day.isDayCompleted &&
        day.completionType === "Manual" &&
        day.inStreak
      ) {
        return COLORS.greenOpacity20;
      }
      if (day.completionType === "Average" && day.isInGoal) {
        return COLORS.purpleOpacity20;
      }
      if (!day.isInGoal) {
        return COLORS.white;
      }
      if (
        day.completionType === "Skip" ||
        day.completionType === "Auto-Skip" ||
        !day.inStreak
      ) {
        return COLORS.mediumGray;
      }
      return COLORS.white;
    };

    const weeks: Record<number, Day[]> = {};
    sortedDays.forEach((day) => {
      const weekNumber = dayjs(day.date).week();
      if (!weeks[weekNumber]) {
        weeks[weekNumber] = [];
      }
      weeks[weekNumber].push(day);
    });

    const getTextColor = (backgroundColor: string): string => {
      if (backgroundColor === COLORS.purpleOpacity20) {
        return COLORS.purple;
      }
      if (backgroundColor === COLORS.lightGray) {
        return COLORS.darkGray;
      }
      if (backgroundColor === COLORS.purple) {
        return COLORS.white;
      }
      return COLORS.textBlack;
    };

    Object.values(weeks).forEach((week) => {
      week.forEach((day, index) => {
        const backgroundColor = getBackgroundColor(day);
        const textColor = getTextColor(backgroundColor);

        const isFirstInGroup =
          index === 0 ||
          getBackgroundColor(week[index - 1]) !== backgroundColor;
        const isLastInGroup =
          index === week.length - 1 ||
          getBackgroundColor(week[index + 1]) !== backgroundColor;
        const isOnlyInGroup = isFirstInGroup && isLastInGroup;
        if (dayjs.utc(day.date).isToday()) {
          return;
        }
        styles[day.date] = {
          container: {
            backgroundColor,
            borderTopLeftRadius: isFirstInGroup ? 100 : 0,
            borderBottomLeftRadius: isFirstInGroup ? 100 : 0,
            borderTopRightRadius: isLastInGroup ? 100 : 0,
            borderBottomRightRadius: isLastInGroup ? 100 : 0,
            ...(isOnlyInGroup ? {} : { width: "100%" }),
          },
          text: { color: textColor },
        };
      });
    });

    return styles;
  }

  const markedDates = useMemo(() => {
    if (data?.statistics) {
      return Object.entries(getCalendarStyles(data?.statistics)).map(
        (item) => ({
          date: item[0],
          styles: item[1],
        })
      );
    }
    return [];
  }, [data, user]);

  const generateDatesForCurrentWeek = (date: Dayjs, selectedDate: Dayjs) => {
    let currentDate = date;
    const week = [];
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate;

      const markedStyles = markedDates?.find((item) => {
        return cloneDate.isSame(dayjs.utc(item.date), "day");
      })?.styles;

      const calculateConsistency = (actual: number, expected: number) => {
        if (actual >= expected) {
          return 100;
        }
        return (actual / expected) * 100;
      };

      const dayStats = data?.statistics.find((item) =>
        cloneDate.isSame(dayjs.utc(item.date), "day")
      );

      const consistency = calculateConsistency(
        dayStats?.deficit || 0,
        dayStats?.expectedDeficit || 0
      );

      week.push(
        <div
          key={cloneDate.toISOString()}
          style={{ width: "100%", height: 57 }}
          className={styles.dayWrapper}
        >
          <div className={clsx(styles.dayStreakContainer)}>
            <div
              style={
                markedStyles?.container ? { ...markedStyles?.container } : {}
              }
              className={styles.dayBackground}
            ></div>
            <div
              className={clsx(styles.day, {
                [styles.inactiveDay]: !cloneDate.isSame(selectedDate, "month"),
                [styles.today]: cloneDate.isToday(),
              })}
              // eslint-disable-next-line no-loop-func
            >
              {cloneDate.format("D")}
            </div>
            {!cloneDate.isBefore(dayjs.utc(user?.onboardingAt)) &&
            !cloneDate.isAfter(dayjs().utc(true)) &&
            cloneDate.isSame(selectedDate, "month") &&
            !cloneDate.isToday() ? (
              <p className={styles.percents}>
                {formatNumberToLocaleString(consistency)}%
              </p>
            ) : null}
          </div>
        </div>
      );
      currentDate = currentDate.add(1, "day");
    }

    return <div className={styles.weekRowContainer}>{week}</div>;
  };

  const getDates = () => {
    const startOfTheSelectedMonth = date.startOf("month");
    const endOfTheSelectedMonth = date.endOf("month");
    const startDate = startOfTheSelectedMonth.startOf("isoWeek");
    const endDate = endOfTheSelectedMonth.endOf("isoWeek");

    let currentDate = startDate;

    const allWeeks = [];

    while (currentDate <= endDate) {
      allWeeks.push(generateDatesForCurrentWeek(currentDate, date));
      currentDate = currentDate.add(7, "day");
    }

    return <div className={styles.weekContainer}>{allWeeks}</div>;
  };

  const getWeekDaysNames = () => {
    const weekStartDate = date.startOf("isoWeek");
    const weekDays = [];
    for (let day = 0; day < 7; day++) {
      weekDays.push(
        <div key={day} style={{ width: "100%" }} className={styles.weekName}>
          {weekStartDate.add(day, "day").format("ddd")}
        </div>
      );
    }
    return weekDays;
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Streak and Consistency Calendar</p>

      {isPending ? (
        <div className={styles.loaderContainer}>
          <PulseLoader color="rgba(97, 42, 255, 1)" />
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <div className={styles.block}>
              <ConsistencyIcon className={styles.consistency} />
              <p>{formatNumberToLocaleString(data?.consistency || 0)}%</p>
            </div>

            <div className={styles.block}>
              <CalendarCheckIcon className={styles.streak} />
              <p>{formatNumberToLocaleString(data?.streak || 0)} days</p>
            </div>
          </div>

          <div className={styles.calendar}>
            <div className={styles.weekDaysNamesContainer}>
              {getWeekDaysNames()}
            </div>

            {getDates()}
          </div>

          <div className={styles.footerChart}>
            <Legend
              label={"Today"}
              description="Calories burn when your intake is less than your caloric expenditure."
              colorOpacity={COLORS.purple}
              color={COLORS.purple}
            />
            <Legend
              label={"Completed"}
              description="A calorie deficit above and beyond your goal."
              color={"rgba(54, 217, 14, 0.6)"}
              colorOpacity={"rgba(54, 217, 14, 0.6)"}
            />
            <Legend
              label={"Skipped"}
              description="Calories are stored when your intake is more than your caloric expenditure."
              color={COLORS.gray}
              colorOpacity={COLORS.gray}
            />

            <Legend
              label={"Estimate"}
              description="Your calorie deficit goal."
              color={COLORS.purpleOpacity80}
              colorOpacity={COLORS.purpleOpacity80}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StreakConsistency;
