import React, { FC, useRef, useState } from "react";
import styles from "./styles.module.scss";
import CheckBox from "../Checkbox";
import { DotsVertical, PencilIcon, TrashIcon } from "../../assets";
import { useOutsideClick } from "../../hooks";
import { motion } from "framer-motion";
import MacroItem from "../MacroItem";
import { IProps } from "./types";
import { format } from "date-fns";
import { Delete, EditIngredient } from "../../modals";
import toast from "react-hot-toast";
import { deleteIngredient } from "../../services/ingredients.service";
// @ts-ignore
import Highlighter from "react-highlight-words";
const IngredientItem: FC<IProps> = ({
  item,
  onGetIngredients,
  searchWord,
}): JSX.Element => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const ref = useRef(null);

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const macro = {
    cal: item?.calories,
    Protein: item?.proteins,
    Fat: item?.fats,
    Carbs: item?.carbs,
    Fiber: item?.fiber,
    Alco: item?.alco,
  };

  const onDelete = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await deleteIngredient(item?.id);
      if (data?.success) {
        toast.success("Ingredient has been successfully deleted");
        onGetIngredients();
      }
    } catch (error) {
      toast.error(error?.message?.[0]?.message || "Something went wrong");
    } finally {
      setIsLoadingDelete(false);
    }
  };
  return (
    <>
      <Delete
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        isLoading={isLoadingDelete}
        onDelete={onDelete}
        title="Are you sure you want to delete this ingredient?"
      />
      <EditIngredient
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        onGetIngredients={onGetIngredients}
        ingredientInfo={item}
      />
      <div ref={ref} className={styles.wrapper}>
        <div className={styles.nameContainer}>
          <p className={styles.name}>
            <Highlighter
              highlightClassName={styles.name}
              searchWords={searchWord?.split(" ")}
              autoEscape={true}
              textToHighlight={item?.name || ""}
            />
            {item?.brand ? (
              <span className={styles.brandName}>
                {" "}
                ·{" "}
                <Highlighter
                  highlightClassName={styles.brandName}
                  searchWords={searchWord?.split(" ")}
                  autoEscape={true}
                  textToHighlight={item?.brand || ""}
                />
              </span>
            ) : (
              ""
            )}
          </p>
          <div>
            <span className={styles.perServing}>Per {item?.serving}:</span>
            <div className={styles.macroContainer}>
              {Object.entries(macro).map((item, index) => (
                <MacroItem key={index} title={item[0]} value={item[1]} />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.categoryContainer}>
          <p>{item?.category}</p>
        </div>
        <div className={styles.dateContainer}>
          <p>
            {item?.updatedAt &&
              format(new Date(item?.updatedAt || ""), "MM.dd.yyyy")}
          </p>
        </div>
        <div className={styles.dateContainer}>
          <p>
            {item?.createdAt &&
              format(new Date(item?.createdAt || ""), "MM.dd.yyyy")}
          </p>
          <DotsVertical onClick={() => setIsOpenMenu((prev) => !prev)} />
        </div>
        {isOpenMenu && (
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className={styles.menuWrapper}
          >
            <div
              onClick={() => {
                setIsOpenEditModal(true);
                setIsOpenMenu(false);
              }}
              className={styles.menuButton}
            >
              <PencilIcon className={styles.editIcon} width={20} height={20} />
              <span style={{}}>Edit</span>
            </div>
            <div
              onClick={() => {
                setIsOpenMenu(false);
                setIsOpenDeleteModal(true);
              }}
              className={styles.menuButton}
            >
              <TrashIcon className={styles.deleteIcon} width={20} height={20} />
              <span style={{ color: "#EE3758" }}>Delete</span>
            </div>
          </motion.div>
        )}
      </div>
    </>
  );
};

export default IngredientItem;
