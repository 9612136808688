import { useRef, useState } from "react";
import { ArrowDownIcon } from "../../assets";
import CalendarWeek from "../CalendarWeek";
import styles from "./styles.module.scss";
import { useAvailableUserDays, useOutsideClick } from "../../hooks";
import { motion } from "framer-motion";
import dayjs, { Dayjs } from "dayjs";
import { IUser } from "../../models";

type DateSelectorProps = {
  date: Dayjs;
  setDate: (date: Dayjs) => void;
  user: IUser;
  markedDates?: Array<{
    date: string;
    showGreenIndicator: boolean;
  }>;
};

const DateSelector: React.FC<DateSelectorProps> = ({
  date,
  setDate,
  user,
  markedDates,
}) => {
  const [isVisiblePicker, setIsVisiblePicker] = useState(false);

  const pickerRef = useRef<HTMLDivElement>(null);

  const { days } = useAvailableUserDays(user);

  useOutsideClick(pickerRef, () => setIsVisiblePicker(false));

  const minDate = days[0];
  const maxDate = days[days.length - 1];

  return (
    <div
      ref={pickerRef}
      onClick={() => setIsVisiblePicker(true)}
      style={{
        backgroundColor: isVisiblePicker ? "rgba(217, 224, 233, 1)" : "#f5f7fa",
      }}
      className={styles.wrapper}
    >
      <span>
        {date.startOf("isoWeek").format("MMM DD")} -{" "}
        {date.endOf("isoWeek").format("MMM DD, YYYY")}
      </span>
      <ArrowDownIcon className={styles.arrow} />
      {isVisiblePicker ? (
        <motion.div
          initial={{ opacity: 0.5, y: -60 }}
          animate={{ opacity: 1, y: 0 }}
          className={styles.pickerContainer}
        >
          <CalendarWeek
            date={date}
            minDate={dayjs.utc(minDate)}
            maxDate={dayjs.utc(maxDate)}
            user={user}
            setDate={(date) => {
              setDate(date);
              setIsVisiblePicker(false);
            }}
          />
        </motion.div>
      ) : null}
    </div>
  );
};

export default DateSelector;
