import React from "react";
import styles from "./styles.module.scss";
import { Button, CircleButton, MonthSelector } from "../../components";
import { ArrowDownIcon } from "../../assets";
import dayjs, { Dayjs } from "dayjs";
import { IUser } from "../../models";
import { useQuery } from "@tanstack/react-query";
import { getMealIntervalLogs } from "../../services/users.service";

type DateRangeProps = {
  date: Dayjs;
  setDate: (date: Dayjs) => void;
  user: IUser;
};

const ProgressDateRange: React.FC<DateRangeProps> = ({
  date,
  setDate,
  user,
}) => {
  const fromDate = date.startOf("month").format("YYYY-MM-DD");
  const toDate = date.endOf("month").format("YYYY-MM-DD");

  useQuery({
    queryKey: ["meal-interval-log", user?.id, fromDate, toDate],
    queryFn: () => {
      return getMealIntervalLogs(user?.id || 0, fromDate, toDate);
    },
  });

  const leftDisabled = date
    .startOf("month")
    .subtract(1, "day")
    .isBefore(user?.onboardingAt, "day");

  const rightDisabled = date
    .endOf("month")
    .add(1, "day")
    .isAfter(user?.last?.lastGeneratedLog?.date, "day");

  const onPrevMonth = () => {
    if (leftDisabled) {
      return;
    }
    setDate(date.subtract(1, "month"));
  };

  const onNextMonth = () => {
    if (rightDisabled) {
      return;
    }
    setDate(date.add(1, "month"));
  };

  return (
    <div className={styles.wrapper}>
      <CircleButton
        onClick={onPrevMonth}
        icon={<ArrowDownIcon className={styles.leftArrow} />}
      />
      <CircleButton
        onClick={onNextMonth}
        icon={<ArrowDownIcon className={styles.rightArrow} />}
      />
      <Button
        size="x-small"
        styleType="outlined"
        title="Today"
        color={"#7D4AFB"}
        customStyles={styles.todayButton}
        onClick={() => setDate(dayjs().utc(true))}
      />

      <MonthSelector date={date} setDate={setDate} user={user} />
    </div>
  );
};

export default ProgressDateRange;
