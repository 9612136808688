import dayjs, { Dayjs } from "dayjs";
import { cloneDeep } from "lodash";

export const moveElementInArray = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number
) => {
  const copyArray = cloneDeep(array);
  const [elementToMove] = copyArray.splice(fromIndex, 1);
  copyArray.splice(toIndex, 0, elementToMove);

  return copyArray;
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const replaceElementInArray = <T>(
  array: T[],
  data: T,
  index: number
) => {
  const copyArray = [...array];
  copyArray.splice(index, 1, data);

  return copyArray;
};

export const lbsToKg = (lbs: number) => {
  return (lbs / 2.205).toFixed(1);
};

export const kgToLbs = (kg: number) => {
  return (kg * 2.205).toFixed(1);
};

export const getDaysOfWeek = (fromDate = new Date()) => {
  const currentWeek = dayjs(fromDate);
  let start = dayjs(currentWeek).utc().startOf("isoWeek");
  const end = dayjs(currentWeek).utc().endOf("isoWeek");

  const days: string[] = [];

  while (!end.isBefore(start, "day")) {
    days.push(start.toISOString().split("T")[0]);
    start = start.add(1, "day");
  }

  return days;
};

export const getPercentage = (value: number, total: number): number => {
  return (value / total) * 100;
};

export const formatNumberToLocaleString = (
  number: number,
  maximumFractionDigits: number = 0,
  minimumFractionDigits: number = 0,
  locale?: string
) => {
  return number?.toLocaleString(locale, {
    maximumFractionDigits,
    minimumFractionDigits,
  });
};

export const getAvailableYears = (registrationDate: Dayjs) => {
  const startYear = dayjs.utc(registrationDate).year();
  const currentYear = dayjs().utc(true).year();

  return Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => `${startYear + i}`
  );
};

export const getAvailableMonthsForYear = (year: number, date: Dayjs) => {
  const givenDate = dayjs(date);
  const registrationYear = givenDate.year();
  const registrationMonth = givenDate.month() + 1;

  const currentYear = dayjs().year();
  const currentMonth = dayjs().month() + 1;

  let startMonth = 1;
  let endMonth = 12;

  if (year === registrationYear) {
    startMonth = registrationMonth;
  }

  if (year === currentYear) {
    endMonth = currentMonth;
  }

  return Array.from({ length: endMonth - startMonth + 1 }, (_, i) =>
    dayjs(`${year}-${String(startMonth + i).padStart(2, "0")}-01`).format(
      "YYYY-MM-DD"
    )
  );
};

export const caloriesToKg = (calories: number) => {
  return calories / 7716.2;
};

export const caloriesToLbs = (calories: number) => {
  return calories / 3500;
};
