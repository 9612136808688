import { useMemo, useRef, useState } from "react";
import { ArrowDownIcon } from "../../assets";
import styles from "./styles.module.scss";
import { useAvailableUserDays, useOutsideClick } from "../../hooks";
import { motion } from "framer-motion";
import dayjs, { Dayjs } from "dayjs";
import { IUser } from "../../models";
import Selector from "../Selector";
import {
  getAvailableMonthsForYear,
  getAvailableYears,
} from "../../utils/methods";
import clsx from "clsx";

type MonthSelectorProps = {
  date: Dayjs;
  setDate: (date: Dayjs) => void;
  user: IUser;
};

const MonthSelector: React.FC<MonthSelectorProps> = ({
  date,
  setDate,
  user,
}) => {
  const [isVisiblePicker, setIsVisiblePicker] = useState(false);

  const pickerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(pickerRef, () => setIsVisiblePicker(false));

  const availableYears = useMemo(() => {
    if (user) {
      return getAvailableYears(dayjs.utc(user?.onboardingAt));
    }
  }, [user]);

  const availableMonths = useMemo(() => {
    if (user) {
      return getAvailableMonthsForYear(
        date.year(),
        dayjs.utc(user?.onboardingAt)
      );
    }
  }, [date, user]);

  return (
    <div
      ref={pickerRef}
      onClick={() => setIsVisiblePicker(true)}
      style={{
        backgroundColor: isVisiblePicker ? "rgba(217, 224, 233, 1)" : "#f5f7fa",
      }}
      className={styles.wrapper}
    >
      <span>{date.format("MMMM YYYY")}</span>
      <ArrowDownIcon className={styles.arrow} />
      {isVisiblePicker ? (
        <motion.div
          initial={{ opacity: 0.5, y: -60 }}
          animate={{ opacity: 1, y: 0 }}
          className={styles.pickerContainer}
        >
          <Selector
            value={date.format("YYYY")}
            setValue={(year) => {
              setDate(dayjs.utc(`${year}-01-01`));
            }}
            data={availableYears ?? []}
            wrapperStyles={styles.yearSelector}
          />

          <p className={styles.sectionTitle}>Select a month</p>
          <div className={styles.monthsContainer}>
            {availableMonths?.map((month, index) => {
              const isSelectedMonth = date.isSame(dayjs.utc(month), "month");
              return (
                <div
                  onClick={() => setDate(dayjs.utc(month))}
                  key={index}
                  className={clsx(styles.month, {
                    [styles.selectedMonth]: isSelectedMonth,
                  })}
                >
                  <span>{dayjs.utc(month).format("MMMM")}</span>
                </div>
              );
            })}
          </div>
        </motion.div>
      ) : null}
    </div>
  );
};

export default MonthSelector;
