import { AxiosResponse } from "axios";
import axiosInstance from "../apis";
import { BASE_URL_V2 } from "../constants";

export const getChartsStatistics = (
  type: "week" | "month" | "year"
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`statistic/${type}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

type GetTotalProgressResponse = {
  data: {
    deficitAvg: null | number;
    deficitSum: number;
    expectedDeficitSum: number;
  };
};

export const getTotalProgress = async (userId?: number) => {
  return (
    await axiosInstance.get<GetTotalProgressResponse>(
      `admin/user/${userId}/progress/total`,
      {
        baseURL: BASE_URL_V2,
      }
    )
  ).data;
};
