import dayjs, { Dayjs } from "dayjs";
import { ArrowSmallLineUpIcon } from "../../assets";
import { IUser } from "../../models";
import styles from "./styles.module.scss";
import { useQuery } from "@tanstack/react-query";
import { getDailyProgress } from "../../services/users.service";
import { useMemo, useState } from "react";
import {
  Bar,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryCursorContainer,
  VictoryLine,
  VictoryScatter,
  VictoryStack,
} from "victory";
import { COLORS } from "../../constants";
import React from "react";
import {
  Button,
  Legend,
  StripesPathComponent,
  TargetDataComponent,
} from "../../components";
import {
  caloriesToKg,
  caloriesToLbs,
  formatNumberToLocaleString,
} from "../../utils/methods";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { first, last } from "lodash";

type MonthlyDeficitChartProps = {
  user: IUser;
  date: Dayjs;
};

const getDaysOfMonth = (fromDate = new Date()) => {
  const currentWeek = dayjs(fromDate);
  let start = dayjs(currentWeek).utc().startOf("months");
  const end = dayjs(currentWeek).utc().endOf("months");

  const days: string[] = [];

  while (!end.isBefore(start, "day")) {
    days.push(start.toISOString().split("T")[0]);
    start = start.add(1, "day");
  }

  return days;
};

type ChartData = {
  x: string;
  y: number;
  y0?: number;
  date: string;
  weight?: number;
  isWeightLogged?: boolean;
  isNonCompletedDay?: boolean;
  logId?: number;
  index?: number;
};

const interpolate = ({
  inputRange,
  outputRange,
}: {
  inputRange: [number, number];
  outputRange: [number, number];
}) => {
  const [inputMin, inputMax] = inputRange;
  const [outputMin, outputMax] = outputRange;

  return (inputValue: number) => {
    const inputRange = inputMax - inputMin;
    const outputRange = outputMax - outputMin;

    const inputValueScaled = (inputValue - inputMin) / inputRange;

    return outputMin + inputValueScaled * outputRange;
  };
};

const MonthlyDeficitChart: React.FC<MonthlyDeficitChartProps> = ({
  user,
  date,
}) => {
  const fromDate = date.startOf("month").format("YYYY-MM-DD");
  const toDate = date.endOf("month").format("YYYY-MM-DD");

  const [selectedChartType, setSelectedChartType] = useState<
    "accumulative" | "daily"
  >("accumulative");

  const [activePoint, setActivePoint] = useState<string | null>(null);
  const navigation = useNavigate();

  const { data, isPending } = useQuery({
    queryKey: ["daily-progress", user?.id, fromDate, toDate],
    queryFn: () => {
      return getDailyProgress(user?.id || 0, fromDate, toDate);
    },
  });

  const daysInWeek = useMemo(() => {
    return getDaysOfMonth(date?.toDate());
  }, [date]);

  const chartData = useMemo(() => {
    let maxValue = 0;
    let minWeight = data?.progress?.[0]?.weight || 0;
    let maxWeight = minWeight;

    const deficitData: ChartData[] = [];
    const superDeficitData: ChartData[] = [];
    const targetData: ChartData[] = [];
    const weightData: ChartData[] = [];
    const xAxisLabels: string[] = [];

    const currentData =
      selectedChartType === "accumulative" ? data?.accumulated : data?.progress;

    currentData?.forEach((item) => {
      const max = Math.max(
        Math.abs(item.deficit),
        Math.abs(item.expectedDeficit)
      );

      if (max > maxValue) {
        maxValue = max;
      }
      if (minWeight > item.weight) {
        minWeight = item.weight;
      }

      if (maxWeight < item.weight) {
        maxWeight = item.weight;
      }
    });

    maxValue = maxValue * 1.15;

    const maxValueWithoutWeight = maxValue;

    maxValue = maxValue * 1.6;

    const interpolateY = interpolate({
      inputRange: [minWeight, maxWeight],
      outputRange: [maxValueWithoutWeight, maxValue],
    });

    maxValue += maxValue * 0.15;

    daysInWeek.forEach((date, index) => {
      xAxisLabels.push(dayjs.utc(date).format("MMM DD"));

      if (
        dayjs.utc(date).isBefore(dayjs.utc(user?.onboardingAt), "day") ||
        dayjs.utc(date).isAfter(dayjs().utc(true), "days")
      ) {
        targetData.push({
          x: date,
          date,
          y: 0,
        });
        deficitData.push({
          x: date,
          date,
          y: 0,
          y0: 0,
          index: index + 1,
        });
        superDeficitData.push({
          x: date,
          date,
          y: 0,
          y0: 0,
        });
        return;
      }

      const statsForDate = currentData?.find((item) => item.date === date);

      if (statsForDate) {
        const currentWeight = statsForDate?.weight || 0;
        let yTween = interpolateY(currentWeight);

        const isDayInGoal = !!statsForDate?.userGoalId;

        if (maxWeight === minWeight) {
          yTween = (maxValue + maxValueWithoutWeight) / 2;
        }

        const isNonCompletedDay =
          statsForDate?.date &&
          !statsForDate?.isDayCompleted &&
          dayjs.utc(statsForDate?.date).isBefore(dayjs().utc(true), "day");

        weightData.push({
          x: statsForDate.date,
          date: statsForDate.date,
          y: yTween,
          weight: statsForDate?.weight || 0,
          isWeightLogged: statsForDate?.isWeightLogged,
          isNonCompletedDay: !!isNonCompletedDay,
        });

        targetData.push({
          date: statsForDate.date,
          x: statsForDate.date,
          y: isDayInGoal ? statsForDate.expectedDeficit : 0,
          y0: 1,
          isNonCompletedDay: !!isNonCompletedDay,
        });

        deficitData.push({
          date: statsForDate.date,
          x: statsForDate.date,
          y: isDayInGoal
            ? statsForDate.deficit - statsForDate.superDeficit
            : statsForDate.deficit,
          y0: 0,
          index: index + 1,
          isNonCompletedDay: !!isNonCompletedDay,
        });

        superDeficitData.push({
          date: statsForDate.date,
          x: statsForDate.date,
          y: isDayInGoal ? statsForDate.superDeficit : 0,
          y0: statsForDate.deficit - statsForDate.superDeficit,
          isNonCompletedDay: !!isNonCompletedDay,
        });
      }
    });

    return {
      maxValue,
      deficitData,
      superDeficitData,
      targetData,
      weightData,
      xAxisLabels,
    };
  }, [data, daysInWeek, user, selectedChartType]);

  const isShowSurplus = useMemo(() => {
    if (selectedChartType === "accumulative") {
      return data?.accumulated?.some((item) => item.deficit < 0);
    } else {
      return data?.progress?.some((item) => item.deficit < 0);
    }
  }, [data, selectedChartType]);

  const isShowSuperDeficit = useMemo(() => {
    return data?.progress?.some((item) => item.superDeficit > 0);
  }, [data]);

  const weightLoggedData = useMemo(() => {
    if (chartData.weightData?.length === 1) {
      return chartData.weightData;
    }
    return chartData.weightData?.filter((item) => item.isWeightLogged);
  }, [chartData.weightData]);

  const weightLoss = useMemo(() => {
    if (data?.progress) {
      return (
        data?.progress?.[0]?.weight -
        data?.progress?.[data?.progress?.length - 1]?.weight
      );
    }
    return 0;
  }, [data]);

  const activeValue = useMemo(() => {
    if (selectedChartType === "accumulative") {
      return data?.accumulated?.find((item) => item.date === activePoint);
    } else {
      return data?.progress?.find((item) => item.date === activePoint);
    }
  }, [activePoint, selectedChartType]);

  const leftSubValue = activePoint
    ? activeValue?.deficit || 0
    : selectedChartType === "accumulative"
    ? data?.deficitSum || 0
    : data?.deficitAvg || 0;

  const leftValue =
    selectedChartType === "accumulative"
      ? user?.weight?.unit === "lbs"
        ? caloriesToLbs(leftSubValue)
        : caloriesToKg(leftSubValue)
      : leftSubValue;

  const findClosestPointSorted = (data: any[], value: number) => {
    if (value === null) return null;
    const start = first(data).index;
    const range = last(data).index - start;
    const index = Math.round(((value - start) / range) * (data.length - 1));
    return data[index];
  };

  const handleCursorChange = (value: number) => {
    setActivePoint(findClosestPointSorted(chartData.deficitData, value)?.date);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Calorie Deficit</p>
        <div
          onClick={() => navigation(`/calorie-deficit-data/${user?.id}`)}
          className={styles.details}
        >
          <p>Details</p>
          <ArrowSmallLineUpIcon className={styles.rightArrow} />
        </div>
      </div>

      {isPending ? (
        <div className={styles.loaderContainer}>
          <PulseLoader color="rgba(97, 42, 255, 1)" />
        </div>
      ) : (
        <div className={styles.chartContainer}>
          <div className={styles.rowContainer}>
            <Button
              onClick={() => setSelectedChartType("accumulative")}
              size="x-small"
              title="Cumulative"
              styleType={
                selectedChartType === "accumulative" ? "filled" : "inactive"
              }
            />
            <Button
              onClick={() => setSelectedChartType("daily")}
              size="x-small"
              title="Daily"
              styleType={selectedChartType === "daily" ? "filled" : "inactive"}
            />
          </div>
          <div className={styles.headerValues}>
            <div className={styles.block}>
              <p className={styles.weight}>
                {formatNumberToLocaleString(leftValue)}{" "}
                <span>
                  {selectedChartType === "accumulative"
                    ? user?.weight?.unit
                    : "cal"}
                </span>
              </p>
              {selectedChartType === "accumulative" ? (
                <p className={styles.calories}>
                  {formatNumberToLocaleString(leftSubValue)} cal
                </p>
              ) : null}
              <p className={styles.title}>
                {selectedChartType === "accumulative"
                  ? "Cumulative Deficit "
                  : activePoint
                  ? "Deficit"
                  : "Average deficit"}{" "}
              </p>
            </div>
            <div className={styles.block}>
              <p className={styles.weight}>
                {activePoint
                  ? activeValue?.isWeightLogged
                    ? formatNumberToLocaleString(activeValue?.weight || 0, 1)
                    : ""
                  : weightLoss > 0
                  ? `-${formatNumberToLocaleString(weightLoss || 0, 1)}`
                  : weightLoss === 0
                  ? "0"
                  : `+${formatNumberToLocaleString(
                      Math.abs(weightLoss) || 0,
                      1
                    )}`}{" "}
                {activePoint ? (
                  !activeValue?.isWeightLogged ? (
                    " "
                  ) : (
                    <span>{user?.weight?.unit}</span>
                  )
                ) : (
                  <span>{user?.weight?.unit}</span>
                )}
              </p>
              <p className={styles.title}>
                {activePoint ? "Weight" : "Weight Loss"}
              </p>
            </div>
          </div>
          <VictoryChart
            maxDomain={{ y: chartData.maxValue }}
            padding={{ left: 0, top: 15, bottom: 15, right: 0 }}
            domainPadding={{ x: [5, 5] }}
            containerComponent={
              <VictoryCursorContainer
                disable={false}
                cursorDimension="x"
                onCursorChange={(s, d) => handleCursorChange(s as number)}
              />
            }
          >
            <defs>
              <pattern
                id={"stripes-green"}
                viewBox="0 0 8 8"
                width="8"
                height="8"
                patternUnits="userSpaceOnUse"
              >
                <polygon points="0,0 4,0 0,4" fill={COLORS.greenOpacity20} />
                <polygon
                  points="0,8 8,0 8,4 4,8"
                  fill={COLORS.greenOpacity20}
                />
                <polygon points="0,4 0,8 8,0 4,0" fill={COLORS.green} />
                <polygon points="4,8 8,8 8,4" fill={COLORS.green} />
              </pattern>
              <pattern
                id={"stripes-gray"}
                viewBox="0 0 8 8"
                width="8"
                height="8"
                patternUnits="userSpaceOnUse"
              >
                <polygon points="0,0 4,0 0,4" fill={COLORS.darkGrayOpacity20} />
                <polygon
                  points="0,8 8,0 8,4 4,8"
                  fill={COLORS.darkGrayOpacity20}
                />
                <polygon points="0,4 0,8 8,0 4,0" fill={COLORS.mediumGray} />
                <polygon points="4,8 8,8 8,4" fill={COLORS.mediumGray} />
              </pattern>
            </defs>
            <VictoryAxis
              dependentAxis={false}
              tickFormat={() => {
                return "";
              }}
              style={{
                axis: {
                  stroke: COLORS.lightGray,
                },
              }}
            />
            <VictoryScatter
              data={weightLoggedData}
              size={3}
              style={{ data: { fill: COLORS.orange } }}
            />
            <VictoryLine
              style={{
                data: {
                  stroke: COLORS.orange,
                  strokeWidth: 1.5,
                },
              }}
              data={chartData.weightData}
            />
            <VictoryStack>
              <VictoryBar
                barWidth={5}
                style={{
                  data: {
                    fill: ({ datum }) => {
                      if (datum?.isNonCompletedDay) {
                        return COLORS.darkGrayOpacity20;
                      }
                      return datum?.y < 0 ? COLORS.red : COLORS.green;
                    },
                    // fillOpacity: ({ active }) => {
                    // return activePoint && !active ? 0.2 : 1;
                    // },
                  },
                }}
                cornerRadius={{
                  top: () => 3,
                }}
                data={chartData.deficitData}
              />
              <VictoryBar
                barWidth={6}
                cornerRadius={{ topRight: 3, topLeft: 3 }}
                style={{
                  data: {
                    fill: ({ datum }) => {
                      if (datum?.isNonCompletedDay) {
                        return "url(#stripes-gray)";
                      }
                      return "url(#stripes-green)";
                    },
                  },
                }}
                dataComponent={
                  <Bar
                    pathComponent={React.createElement(StripesPathComponent)}
                  />
                }
                data={chartData.superDeficitData}
              />
            </VictoryStack>
            <VictoryScatter
              style={{ data: { fill: COLORS.purple } }}
              // @ts-expect-error - missing in types
              dataComponent={<TargetDataComponent width={5} />}
              data={chartData.targetData}
            />
          </VictoryChart>
          <div className={styles.xAxisContainer}>
            <p className={styles.xAxisText}>{chartData?.xAxisLabels?.[0]}</p>

            <p className={styles.xAxisText}>
              {chartData?.xAxisLabels?.[chartData?.xAxisLabels?.length - 1]}
            </p>
          </div>
          <div className={styles.footerChart}>
            <Legend
              label={"Deficit"}
              description="Calories burn when your intake is less than your caloric expenditure."
              colorOpacity={COLORS.green}
              color={COLORS.green}
              variant="border"
            />
            {isShowSuperDeficit ? (
              <Legend
                label={"Super Deficit"}
                description="A calorie deficit above and beyond your goal."
                color={COLORS.green}
                colorOpacity={COLORS.greenOpacity20}
                variant="half-fade"
              />
            ) : null}
            {isShowSurplus ? (
              <Legend
                label={"Surplus"}
                description="Calories are stored when your intake is more than your caloric expenditure."
                color={COLORS.red}
                colorOpacity={COLORS.red}
              />
            ) : null}

            <Legend
              label={"Goal"}
              description="Your calorie deficit goal."
              variant="line"
              color={COLORS.purple}
              colorOpacity={COLORS.purple}
            />

            <Legend
              label={"Weight"}
              description=""
              variant="line"
              color={COLORS.orange}
              colorOpacity={COLORS.orange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthlyDeficitChart;
