import { useMemo, useRef, useState } from "react";
import { ArrowSmallDownIcon } from "../../assets";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { Dayjs } from "dayjs";
import { IUser } from "../../models";
import { useQuery } from "@tanstack/react-query";
import { getMealIntervalLogs } from "../../services/users.service";

type CalendarWeekProps = {
  date: Dayjs;
  setDate: (date: Dayjs) => void;
  minDate: Dayjs;
  maxDate: Dayjs;
  user?: IUser;
};

const CalendarWeek: React.FC<CalendarWeekProps> = ({
  date,
  setDate,
  minDate,
  maxDate,
  user,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [selectedDate, setSelectedDate] = useState(date);

  const fromDate = selectedDate.startOf("month").format("YYYY-MM-DD");
  const toDate = selectedDate.endOf("month").format("YYYY-MM-DD");

  const { data: mealInterval } = useQuery({
    queryKey: ["meal-interval-log", user?.id, fromDate, toDate],
    queryFn: () => {
      return getMealIntervalLogs(user?.id || 0, fromDate, toDate);
    },
  });

  const markedDates = useMemo(() => {
    return mealInterval?.map((log) => ({
      date: log.date,
      showGreenIndicator: log?.isDayCompleted,
    }));
  }, [mealInterval]);

  const leftDisabled = selectedDate
    .startOf("month")
    .subtract(1, "day")
    .isBefore(user?.onboardingAt, "day");

  const rightDisabled = selectedDate
    .endOf("month")
    .add(1, "day")
    .isAfter(user?.last?.lastGeneratedLog?.date, "day");

  const onPrevMonth = () => {
    if (leftDisabled) {
      return;
    }
    setSelectedDate(selectedDate.startOf("month").subtract(1, "month"));
  };

  const onNextMonth = () => {
    if (rightDisabled) {
      return;
    }
    setSelectedDate(selectedDate.startOf("month").add(1, "month"));
  };

  const generateDatesForCurrentWeek = (
    date: Dayjs,
    selectedDate: Dayjs,
    activeDate: Dayjs
  ) => {
    let currentDate = date;
    const week = [];
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate;
      const markedOptions = markedDates?.find(
        (item) => item.date === cloneDate.toISOString()?.split("T")[0]
      );
      week.push(
        <div
          key={cloneDate.toISOString()}
          style={{ width: 40, height: 40 }}
          className={styles.dayWrapper}
        >
          <div className={clsx(styles.dayStreakContainer)}>
            <div
              className={clsx(styles.day, {
                [styles.inactiveDay]:
                  !cloneDate.isSame(selectedDate, "month") ||
                  cloneDate.isBefore(minDate, "day") ||
                  cloneDate.isAfter(maxDate, "day"),
                [styles.selectedDay]:
                  cloneDate.isSame(activeDate, "day") &&
                  cloneDate.isSame(selectedDate, "month"),
                [styles.today]:
                  cloneDate.isSame(activeDate, "day") &&
                  cloneDate.isSame(selectedDate, "month"),
              })}
              // eslint-disable-next-line no-loop-func
              onClick={() => {
                console.log(
                  !cloneDate.isSame(selectedDate, "month"),
                  cloneDate.isBefore(minDate, "day"),
                  cloneDate.isAfter(maxDate, "day")
                );
                if (
                  !cloneDate.isSame(selectedDate, "month") ||
                  cloneDate.isBefore(minDate, "day") ||
                  cloneDate.isAfter(maxDate, "day")
                ) {
                  return;
                }
                setDate(cloneDate);
                setSelectedDate(cloneDate);
              }}
            >
              {cloneDate.format("D")}
              {markedOptions?.showGreenIndicator ? (
                <div className={styles.green} />
              ) : null}
            </div>
          </div>
        </div>
      );
      currentDate = currentDate.add(1, "day");
    }

    return (
      <div className={styles.weekRowContainer}>
        {activeDate.isSame(selectedDate, "month") &&
        date.isSame(activeDate, "isoWeek") ? (
          <div className={styles.weekBackground} />
        ) : null}
        {week}
      </div>
    );
  };

  const getDates = () => {
    const startOfTheSelectedMonth = selectedDate.startOf("month");
    const endOfTheSelectedMonth = selectedDate.endOf("month");
    const startDate = startOfTheSelectedMonth.startOf("isoWeek");
    const endDate = endOfTheSelectedMonth.endOf("isoWeek");

    let currentDate = startDate;

    const allWeeks = [];

    while (currentDate <= endDate) {
      allWeeks.push(
        generateDatesForCurrentWeek(currentDate, selectedDate, date)
      );
      currentDate = currentDate.add(7, "day");
    }

    return <div className={styles.weekContainer}>{allWeeks}</div>;
  };

  const getWeekDaysNames = () => {
    const weekStartDate = selectedDate.startOf("isoWeek");
    const weekDays = [];
    for (let day = 0; day < 7; day++) {
      weekDays.push(
        <div key={day} style={{ width: 40 }} className={styles.weekName}>
          {weekStartDate.add(day, "day").format("ddd")}
        </div>
      );
    }
    return weekDays;
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.dateHeader}>
        <div onClick={onPrevMonth} className={styles.dateHeaderButton}>
          <ArrowSmallDownIcon className={styles.leftArrow} />
        </div>
        <p className={styles.dateTitle}>{selectedDate.format("MMM, YYYY")}</p>
        <div onClick={onNextMonth} className={styles.dateHeaderButton}>
          <ArrowSmallDownIcon className={styles.rightArrow} />
        </div>
      </div>
      <div className={styles.weekDaysNamesContainer}>{getWeekDaysNames()}</div>

      {getDates()}
    </div>
  );
};

export default CalendarWeek;
