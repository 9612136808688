import { LogActivity } from "../../services/users.service";
import { formatNumberToLocaleString } from "../../utils/methods";
import ActivityIcon from "../ActivityIcon";
import styles from "./styles.module.scss";

interface ActivityCardProps {
  activity: LogActivity;
}

const ActivityCard: React.FC<ActivityCardProps> = ({ activity }) => {
  return (
    <div className={styles.wrapper}>
      <ActivityIcon />
      <div className={styles.contentContainer}>
        <p>{activity.type}</p>
        <span>{formatNumberToLocaleString(activity.minutes)} minutes</span>
      </div>
      <div className={styles.contentContainer}>
        <p>Source: {activity?.isTracker ? "Tracker" : "Manual"} </p>
      </div>
      <div style={{ flex: 0.5 }} className={styles.contentContainer}>
        <span className={styles.calorie}>
          {formatNumberToLocaleString(activity.calories, 1)} cal
        </span>
      </div>
    </div>
  );
};

export default ActivityCard;
