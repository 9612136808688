import { useEffect, useState } from "react";
import { CircleProgress, Progress } from "../../components";
import { MealIntervalLog } from "../../services/users.service";
import styles from "./styles.module.scss";
import { formatNumberToLocaleString } from "../../utils/methods";

type WeeklyDeficitProps = {
  selectedWeekMealInterval?: MealIntervalLog[];
};

const WeeklyDeficit: React.FC<WeeklyDeficitProps> = ({
  selectedWeekMealInterval,
}) => {
  const [weeklyDeficit, setWeeklyDeficit] = useState<{
    expectedDeficit: number;
    currentDeficit: number;
    progress: number;
  }>({
    expectedDeficit: 0,
    currentDeficit: 0,
    progress: 0,
  });

  useEffect(() => {
    if (selectedWeekMealInterval) {
      const expectedDeficit =
        selectedWeekMealInterval?.reduce((acc, cur) => {
          if (cur?.userGoalId) {
            return acc + cur.expected.calorieDeficitPerDay;
          }
          return acc;
        }, 0) || 0;

      const currentDeficit =
        selectedWeekMealInterval?.reduce((acc, cur) => {
          if (cur?.userGoalId && cur.isDayCompleted) {
            return acc + cur.loggedValues.deficit;
          }
          return acc;
        }, 0) || 0;

      const progress = (currentDeficit / expectedDeficit) * 100;

      setWeeklyDeficit({
        expectedDeficit: +expectedDeficit?.toFixed(2),
        currentDeficit: +currentDeficit?.toFixed(2),
        progress,
      });
    }
  }, [selectedWeekMealInterval]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Weekly Deficit</p>

      <div className={styles.container}>
        <CircleProgress value={weeklyDeficit.progress / 100} title="2552 cal" />
        <Progress progress={weeklyDeficit.progress / 100} />
        <p className={styles.value}>
          {formatNumberToLocaleString(weeklyDeficit.currentDeficit, 1)} /{" "}
          {formatNumberToLocaleString(weeklyDeficit.expectedDeficit, 1)} cal
        </p>
      </div>
    </div>
  );
};

export default WeeklyDeficit;
