import { LogActivity } from "../../services/users.service";
import ActivityCard from "../ActivityCard";
import LoggedContainer from "../LoggedContainer";
import styles from "./styles.module.scss";

type ActivitySectionProps = {
  activities: LogActivity[];
};

const ActivitySection: React.FC<ActivitySectionProps> = ({ activities }) => {
  return (
    <div className={styles.wrapper}>
      <h6>Activity</h6>
      <div className={styles.activityContainer}>
        {activities?.map((item, i) => {
          const listLength = activities.length;
          const order =
            listLength === 1
              ? "single"
              : i === 0
              ? "first"
              : i === listLength - 1
              ? "last"
              : "middle";

          return (
            <div>
              <LoggedContainer
                order={order}
                status={item?.isLogged ? "logged" : "planned"}
              >
                <ActivityCard activity={item} />
              </LoggedContainer>
              {i !== activities?.length - 1 ? (
                <div
                  style={{
                    backgroundColor:
                      activities?.[i + 1]?.isLogged || item?.isLogged
                        ? "rgba(54, 217, 14, 1)"
                        : "rgba(245, 247, 250, 1)",
                  }}
                  className={styles.activityDivider}
                />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActivitySection;
