import { useNavigate, useParams } from "react-router-dom";
import { ArrowDownIcon, ArrowLineIcon } from "../../../assets";
import styles from "./styles.module.scss";
import { useEffect, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { getDailyProgress, getUserById } from "../../../services/users.service";
import { useQuery } from "@tanstack/react-query";
import { formatNumberToLocaleString } from "../../../utils/methods";
import { COLORS } from "../../../constants";
import { PulseLoader } from "react-spinners";
import { IUser } from "../../../models";

const CalorieDeficitDataPoints = () => {
  const navigation = useNavigate();
  const [userProfile, setUserProfile] = useState<IUser>();

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const params = useParams();

  const onGetUserProfile = async (id: string) => {
    try {
      setIsLoadingProfile(true);
      const { data } = await getUserById(id);
      if (data?.data) {
        setUserProfile(data?.data);
      }
    } catch (error) {
    } finally {
      setIsLoadingProfile(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      onGetUserProfile(params?.id);
    }
  }, [params?.id]);

  const [date, setDate] = useState<Dayjs>(dayjs().utc(true));

  const fromDate = date.startOf("isoWeek").format("YYYY-MM-DD");
  const toDate = date.endOf("isoWeek").format("YYYY-MM-DD");

  const leftDisabled = date
    .startOf("isoWeek")
    .subtract(1, "day")
    .isBefore(userProfile?.onboardingAt, "day");

  const rightDisabled = date
    .endOf("isoWeek")
    .add(1, "day")
    .isAfter(dayjs().utc(true), "day");

  const { data, isPending } = useQuery({
    queryKey: ["daily-progress", params?.id, fromDate, toDate],
    queryFn: () => {
      return getDailyProgress(params?.id ? +params.id : 0, fromDate, toDate);
    },
  });

  const values = useMemo(() => {
    if (data?.progress) {
      const totalValues = data?.progress.reduce(
        (acc, cur) => {
          return {
            proteins: acc.proteins + cur.loggedValues.proteins,
            fats: acc.fats + cur.loggedValues.fats,
            carbs: acc.carbs + cur.loggedValues.carbs,
            fiber: acc.fiber + cur.loggedValues.fiber,
            alco: acc.alco + cur.loggedValues.alco,
            weight: acc.weight + cur.weight,
            intake: acc.intake + cur.intake,
            deficit: acc.deficit + cur.deficit,
            BMR: acc.BMR + cur.BMR,
            NEAT: acc.NEAT + cur.NEAT,
          };
        },
        {
          weight: 0,
          intake: 0,
          deficit: 0,
          BMR: 0,
          NEAT: 0,
          proteins: 0,
          fats: 0,
          carbs: 0,
          fiber: 0,
          alco: 0,
        }
      );

      const averageValues = {
        proteins: totalValues.proteins / data?.progress.length,
        fats: totalValues.fats / data?.progress.length,
        carbs: totalValues.carbs / data?.progress.length,
        fiber: totalValues.fiber / data?.progress.length,
        alco: totalValues.alco / data?.progress.length,
        weight: totalValues.weight / data?.progress.length,
        intake: totalValues.intake / data?.progress.length,
        deficit: totalValues.deficit / data?.progress.length,
        BMR: totalValues.BMR / data?.progress.length,
        NEAT: totalValues.NEAT / data?.progress.length,
      };

      return { averageValues, totalValues };
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <ArrowLineIcon
          onClick={() => navigation(-1)}
          className={styles.headerArrowButton}
        />
        <h5 className={styles.headerTitle}>Calorie Deficit Data Points</h5>
      </header>
      <div className={styles.contentContainer}>
        <div className={styles.dateRange}>
          <ArrowLineIcon
            onClick={() => {
              if (leftDisabled) {
                return;
              }
              setDate((prev) => prev.subtract(1, "week"));
            }}
            className={styles.arrowLeft}
          />
          <div className={styles.info}>
            <h6 className={styles.date}>
              {dayjs.utc(fromDate).format("MMM DD")} -{" "}
              {dayjs.utc(toDate).format("MMM DD, YYYY")}
            </h6>
            <p className={styles.title}>Total Calorie Deficit</p>
            <p className={styles.value}>
              {formatNumberToLocaleString(values?.totalValues.deficit || 0, 1)}
            </p>
          </div>
          <ArrowLineIcon
            onClick={() => {
              if (rightDisabled) {
                return;
              }
              setDate((prev) => prev.add(1, "week"));
            }}
            className={styles.arrowRight}
          />
        </div>
        {isPending || isLoadingProfile ? (
          <div className={styles.loaderWrapper}>
            <PulseLoader color={"#7D4AFB"} />
          </div>
        ) : (
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div style={{ flex: 1 }} className={styles.tableHeaderTitle}>
                Date
              </div>
              <div className={styles.tableHeaderTitle}>Protein</div>
              <div className={styles.tableHeaderTitle}>Fat</div>
              <div className={styles.tableHeaderTitle}>Carbs</div>
              <div className={styles.tableHeaderTitle}>Fiber</div>
              <div className={styles.tableHeaderTitle}>Alcohol</div>
              <div className={styles.tableHeaderTitle}>Cals IN</div>
              <div className={styles.tableHeaderTitle}>Cals OUT</div>
              <div className={styles.tableHeaderTitle}>Deficit</div>
              <div className={styles.tableHeaderTitle}>Weight</div>
            </div>
            {data?.progress?.map((item, index) => {
              return (
                <div key={index} className={styles.tableValues}>
                  <div style={{ flex: 1 }} className={styles.tableValueTitle}>
                    {dayjs.utc(item.date).format("MMM DD, YYYY")}
                  </div>
                  <div className={styles.tableValueTitle}>
                    {formatNumberToLocaleString(item.loggedValues.proteins, 1)}
                  </div>
                  <div className={styles.tableValueTitle}>
                    {formatNumberToLocaleString(item.loggedValues.fats, 1)}
                  </div>
                  <div className={styles.tableValueTitle}>
                    {formatNumberToLocaleString(item.loggedValues.carbs, 1)}
                  </div>
                  <div className={styles.tableValueTitle}>
                    {formatNumberToLocaleString(item.loggedValues.fiber, 1)}
                  </div>
                  <div className={styles.tableValueTitle}>
                    {formatNumberToLocaleString(item.loggedValues.alco, 1)}
                  </div>
                  <div className={styles.tableValueTitle}>
                    {formatNumberToLocaleString(item.intake, 1)}
                  </div>
                  <div className={styles.tableValueTitle}>
                    {formatNumberToLocaleString(item.BMR + item.NEAT, 1)}
                  </div>
                  <div
                    style={{
                      color:
                        item?.deficit === 0
                          ? undefined
                          : item.deficit > 0
                          ? COLORS.green
                          : COLORS.red,
                    }}
                    className={styles.tableValueTitle}
                  >
                    {formatNumberToLocaleString(item.deficit, 1)}
                  </div>
                  <div className={styles.tableValueTitle}>
                    {item?.isWeightLogged
                      ? formatNumberToLocaleString(item.weight, 1)
                      : "-"}
                  </div>
                </div>
              );
            })}
            <div className={styles.tableValues}>
              <div style={{ flex: 1 }} className={styles.tableValueTitleBold}>
                Average
              </div>
              <div className={styles.tableValueTitleBold}>
                {formatNumberToLocaleString(
                  values?.averageValues.proteins || 0,
                  1
                )}
              </div>
              <div className={styles.tableValueTitleBold}>
                {formatNumberToLocaleString(values?.averageValues.fats || 0, 1)}
              </div>
              <div className={styles.tableValueTitleBold}>
                {formatNumberToLocaleString(
                  values?.averageValues.carbs || 0,
                  1
                )}
              </div>
              <div className={styles.tableValueTitleBold}>
                {formatNumberToLocaleString(
                  values?.averageValues.fiber || 0,
                  1
                )}
              </div>
              <div className={styles.tableValueTitleBold}>
                {formatNumberToLocaleString(values?.averageValues.alco || 0, 1)}
              </div>
              <div className={styles.tableValueTitleBold}>
                {formatNumberToLocaleString(
                  values?.averageValues.intake || 0,
                  1
                )}
              </div>
              <div className={styles.tableValueTitleBold}>
                {formatNumberToLocaleString(
                  (values?.averageValues.BMR || 0) +
                    (values?.averageValues.NEAT || 0),
                  1
                )}
              </div>
              <div
                style={{
                  color:
                    values?.averageValues?.deficit === 0
                      ? undefined
                      : values && values?.averageValues.deficit > 0
                      ? COLORS.green
                      : COLORS.red,
                }}
                className={styles.tableValueTitleBold}
              >
                {formatNumberToLocaleString(
                  values?.averageValues.deficit || 0,
                  1
                )}
              </div>
              <div className={styles.tableValueTitleBold}>
                {formatNumberToLocaleString(
                  values?.averageValues.weight || 0,
                  1
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalorieDeficitDataPoints;
