import { CheckIcon } from "../../assets";
import styles from "./styles.module.scss";

type StatusBadgeProps = {
  status: "logged" | "planned";
};

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  return (
    <div
      style={{
        backgroundColor:
          status === "logged"
            ? "rgba(54, 217, 14, 1)"
            : "rgba(245, 247, 250, 1)",
      }}
      className={styles.wrapper}
    >
      {status === "logged" ? (
        <CheckIcon style={{ width: 10, height: 10 }} />
      ) : null}
      <span
        style={{
          color: status === "logged" ? "white" : "rgba(106, 115, 127, 1)",
        }}
      >
        {status === "logged" ? "LOGGED" : "PLANNED"}
      </span>
    </div>
  );
};

export default StatusBadge;
